import React from "react";
import './Star.css';
const Star = ({ value }) => {
  if (value >= 1) {
    return <span className="star-span">★</span>; // Full star
  } else if (value >= 0.5) {
    return <span className="star-span">{/* ½ */}✮</span>; // Half star
  } else {
    return <span className="star-span">☆</span>; // Empty star
  }
};

export default Star;
