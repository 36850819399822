import React from "react";
import ph from "../images/logo/healthcare.jpg";
import hospital from "../images/icons/hospital.png";
import clinic from "../images/icons/clinics.jpg";
import nurse from "../images/nhomes.jpg";
import heartbeat from "../images/icons/onco.jpg";
import abdm from "../images/icons/abdm_logo.png";
import hcare from "../images/hcare.jpg";
import bbms from "../images/icons/bbms.jpg";
import "./Solutions.css";
import { motion } from "framer-motion";

const SolutionCard = ({ title, subtitle, imageUrl }) => {
  return (
    <motion.div
      className="solution-card"
      initial={{ opacity: 0, y: 20 }}
      animate={{ opacity: 1, y: 0 }}
      whileHover={{ scale: 1.05 }}
      whileTap={{ scale: 0.95 }}
      transition={{ duration: 0.3 }}
    >
      <div className="solution-card-image">
        <img src={imageUrl} alt={`${title} icon`} />
      </div>
      <div className="solution-card-content">
        <h2 className="solution-card-title">{title}</h2>
        {/* <p className="solution-card-subtitle">{subtitle}</p> */}
      </div>
    </motion.div>
  );
};

const Solutions = () => {
  const solutions = [
    {
      title: "Enterprise Hospitals",
      subtitle: "ZERO - Server Costs, Upgrade Fees, Support Fees",
      bgImg: hospital,
    },
    {
      title: "Public Health",
      subtitle: "# 1 Clinical Transformation Company in AP, ME and EE",
      bgImg: ph,
    },
    {
      title: "Nursing Homes",
      subtitle:
        "ZERO - Medication Errors, Implementation Hassles, Licence Fees",
      bgImg: nurse,
    },
    {
      title: "Blood Bank Management System",
      subtitle:
        "168 modules, 43 Core Processes 1 Patient, 1 Medical Record For Life",
      bgImg: bbms,
    },
    {
      title: "ONCO Hospitals",
      subtitle: "Care Coordination Transition of Care Patient Engagement",
      bgImg: heartbeat,
    },
    {
      title: "ABDM",
      subtitle: "  is now ABDM Compliant (M1,M2,M3 Certified)",
      bgImg: abdm,
    },
    {
      title: "Homebased Homecare",
      subtitle: "  is now ABDM Compliant (M1,M2,M3 Certified)",
      bgImg: hcare,
    },
    {
      title: "Clinics",
      subtitle:
        "168 modules, 43 Core Processes 1 Patient, 1 Medical Record For Life",
      bgImg: clinic,
    },
  ];
  return (
    <>
      <motion.div
        className="sol-head"
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
        transition={{ duration: 0.5 }}
      >
        <div className="h-line"></div>
        <h1>Our Solutions</h1>
        <div className="h-line"></div>
      </motion.div>
      <div className="solutions">
        {solutions.map((slider, index) => (
          <SolutionCard
            key={index}
            title={slider.title}
            subtitle={slider.subtitle}
            imageUrl={slider.bgImg}
          />
        ))}
      </div>
    </>
  );
};

export default Solutions;


/* import React from "react";
import ph from "../images/icons/healthcare.png";
import hospital from "../images/icons/hospital.png";
import clinic from "../images/icons/clinic.png";
import nurse from "../images/icons/nurse.png";
import heartbeat from "../images/icons/heartbeat.png";
import abdm from "../images/icons/abdm.png";
import "./Solutions.css";
import { motion } from "framer-motion";

const SolutionCard = ({ title, subtitle, imageUrl }) => {
  return (
    <motion.div
      className="solution-card"
      initial={{ opacity: 0, x: 100 }}
      whileInView={{ opacity: 1, x: 0 }}
      whileHover={{ scale: 1.03 }}
      whileTap={{ scale: 0.9 }}
    >
      <div className="solution-card-image">
        <img src={imageUrl} alt="" />
      </div>
      <div className="solution-card-content">
        <h2 className="solution-card-title">{title}</h2>
        <p className="solution-card-subtitle">{subtitle}</p>
      </div>
    </motion.div>
  );
};

const Solutions = () => {
  const solutions = [
    {
      title: "Enterprise Hospitals",
      subtitle: "ZERO - Server Costs, Upgrade Fees, Support Fees",
      bgImg: hospital,
    },
    {
      title: "Public Health",
      subtitle: "# 1 Clinical Transformation Company in AP, ME and EE",
      bgImg: ph,
    },
    {
      title: "Nursing Homes",
      subtitle:
        "ZERO - Medication Errors, Implementation Hassles, Licence Fees",
      bgImg: nurse,
    },
    {
      title: "Clinics",
      subtitle:
        "168 modules, 43 Core Processes 1 Patient, 1 Medical Record For Life",
      bgImg: clinic,
    },
    {
      title: "ONCO Hospitals",
      subtitle: "Care Coordination Transition of Care Patient Engagement",
      bgImg: heartbeat,
    },
    {
      title: "ABDM",
      subtitle: "  is now ABDM Compliant (M1,M2,M3 Certified)",
      bgImg: abdm,
    },
    {
      title: "Homebased Homecare",
      subtitle: "  is now ABDM Compliant (M1,M2,M3 Certified)",
      bgImg: abdm,
    },
    {
      title: "Thinking...",
      subtitle: "  is now ABDM Compliant (M1,M2,M3 Certified)",
      bgImg: abdm,
    },
  ];
  return (
    <>
      <motion.div
        className="sol-head"
        initial={{ opacity: 0 }}
        whileInView={{ opacity: 1 }}
        exit={{ opacity: 0 }}
        transition={{ duration: 0.2 }}
      >
        <div className="h-line"></div>
        <h1>Solutions</h1>
        <div className="h-line"></div>
      </motion.div>
      <div className="solutions">
        {solutions.map((slider, index) => (
          <SolutionCard
            key={index}
            title={slider.title}
            subtitle={slider.subtitle}
            imageUrl={slider.bgImg}
          />
        ))}
      </div>
    </>
  );
};

export default Solutions;
 */