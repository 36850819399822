import OrganizationHierarchy from "./OrganizationHierarchy";
import person from '../images/person.png';
const orgData = [
    { id: 1, name: "Alice Johnson", designation: "CEO", imageUrl:person, subordinates: [2, 3, 4/* , 5 */], description:"When I first joined this remarkable organization, we were a team fueled by ambition and potential. We had a clear vision—to not only lead in our industry but to set standards of excellence and innovation. Since then, we've not only achieved these goals but surpassed them, thanks to your hard work and dedication." },
    { id: 2, name: "Bob Smith", designation: "CTO", imageUrl:person, subordinates: [5], description:"When I first joined this remarkable organization, we were a team fueled by ambition and potential. We had a clear vision—to not only lead in our industry but to set standards of excellence and innovation. Since then, we've not only achieved these goals but surpassed them, thanks to your hard work and dedication." },
    { id: 3, name: "Carol White", designation: "CFO", imageUrl:person, subordinates: [6], description:"When I first joined this remarkable organization, we were a team fueled by ambition and potential. We had a clear vision—to not only lead in our industry but to set standards of excellence and innovation. Since then, we've not only achieved these goals but surpassed them, thanks to your hard work and dedication." },
    { id: 4, name: "Dave Brown", designation: "COO", imageUrl:person, subordinates: [7], description:"When I first joined this remarkable organization, we were a team fueled by ambition and potential. We had a clear vision—to not only lead in our industry but to set standards of excellence and innovation. Since then, we've not only achieved these goals but surpassed them, thanks to your hard work and dedication." },
    { id: 5, name: "Eve Davis", designation: "CMO", imageUrl:person, subordinates: [8], description:"When I first joined this remarkable organization, we were a team fueled by ambition and potential. We had a clear vision—to not only lead in our industry but to set standards of excellence and innovation. Since then, we've not only achieved these goals but surpassed them, thanks to your hard work and dedication." },
    { id: 6, name: "Frank Green", designation: "Senior Architect", imageUrl:person, subordinates: [9], description:"When I first joined this remarkable organization, we were a team fueled by ambition and potential. We had a clear vision—to not only lead in our industry but to set standards of excellence and innovation. Since then, we've not only achieved these goals but surpassed them, thanks to your hard work and dedication." },
    { id: 7, name: "Grace Hopper", designation: "Lead Developer", imageUrl:person, subordinates: [10], description:"When I first joined this remarkable organization, we were a team fueled by ambition and potential. We had a clear vision—to not only lead in our industry but to set standards of excellence and innovation. Since then, we've not only achieved these goals but surpassed them, thanks to your hard work and dedication." },
    { id: 8, name: "Henry Ford", designation: "Account Manager", imageUrl:person, subordinates: [11], description:"When I first joined this remarkable organization, we were a team fueled by ambition and potential. We had a clear vision—to not only lead in our industry but to set standards of excellence and innovation. Since then, we've not only achieved these goals but surpassed them, thanks to your hard work and dedication." },
    { id: 9, name: "Ivy Bell", designation: "Financial Analyst", imageUrl:person, subordinates: [12], description:"When I first joined this remarkable organization, we were a team fueled by ambition and potential. We had a clear vision—to not only lead in our industry but to set standards of excellence and innovation. Since then, we've not only achieved these goals but surpassed them, thanks to your hard work and dedication." },
    { id: 10, name: "Jack Hill", designation: "Operations Manager", imageUrl:person, subordinates: [13], description:"When I first joined this remarkable organization, we were a team fueled by ambition and potential. We had a clear vision—to not only lead in our industry but to set standards of excellence and innovation. Since then, we've not only achieved these goals but surpassed them, thanks to your hard work and dedication." },
    { id: 11, name: "Kim Lee", designation: "HR Manager", imageUrl:person, subordinates: [14], description:"When I first joined this remarkable organization, we were a team fueled by ambition and potential. We had a clear vision—to not only lead in our industry but to set standards of excellence and innovation. Since then, we've not only achieved these goals but surpassed them, thanks to your hard work and dedication." },
    { id: 12, name: "Larry Page", designation: "PR Manager", imageUrl:person, subordinates: [15], description:"When I first joined this remarkable organization, we were a team fueled by ambition and potential. We had a clear vision—to not only lead in our industry but to set standards of excellence and innovation. Since then, we've not only achieved these goals but surpassed them, thanks to your hard work and dedication." },
    { id: 13, name: "Mindy Kaling", designation: "Brand Manager", imageUrl:person, subordinates: [16], description:"When I first joined this remarkable organization, we were a team fueled by ambition and potential. We had a clear vision—to not only lead in our industry but to set standards of excellence and innovation. Since then, we've not only achieved these goals but surpassed them, thanks to your hard work and dedication." },
    { id: 14, name: "Nate Archibald", designation: "Software Engineer", imageUrl:person, subordinates: [], description:"When I first joined this remarkable organization, we were a team fueled by ambition and potential. We had a clear vision—to not only lead in our industry but to set standards of excellence and innovation. Since then, we've not only achieved these goals but surpassed them, thanks to your hard work and dedication." },
    { id: 15, name: "Olivia Pope", designation: "Systems Analyst", imageUrl:person, subordinates: [], description:"When I first joined this remarkable organization, we were a team fueled by ambition and potential. We had a clear vision—to not only lead in our industry but to set standards of excellence and innovation. Since then, we've not only achieved these goals but surpassed them, thanks to your hard work and dedication." },
    { id: 16, name: "Peter Parker", designation: "Web Developer", imageUrl:person, subordinates: [], description:"When I first joined this remarkable organization, we were a team fueled by ambition and potential. We had a clear vision—to not only lead in our industry but to set standards of excellence and innovation. Since then, we've not only achieved these goals but surpassed them, thanks to your hard work and dedication." },
    /* { id: 17, name: "Quinn Fabray", designation: "App Developer", imageUrl:person, subordinates: [], description:"When I first joined this remarkable organization, we were a team fueled by ambition and potential. We had a clear vision—to not only lead in our industry but to set standards of excellence and innovation. Since then, we've not only achieved these goals but surpassed them, thanks to your hard work and dedication." },
    { id: 18, name: "Rachel Green", designation: "Sales Representative", imageUrl:person, subordinates: [], description:"When I first joined this remarkable organization, we were a team fueled by ambition and potential. We had a clear vision—to not only lead in our industry but to set standards of excellence and innovation. Since then, we've not only achieved these goals but surpassed them, thanks to your hard work and dedication." },
    { id: 19, name: "Steve Jobs", designation: "Sales Support", imageUrl:person, subordinates: [], description:"When I first joined this remarkable organization, we were a team fueled by ambition and potential. We had a clear vision—to not only lead in our industry but to set standards of excellence and innovation. Since then, we've not only achieved these goals but surpassed them, thanks to your hard work and dedication." },
    { id: 20, name: "Tony Stark", designation: "Investment Analyst", imageUrl:person, subordinates: [], description:"When I first joined this remarkable organization, we were a team fueled by ambition and potential. We had a clear vision—to not only lead in our industry but to set standards of excellence and innovation. Since then, we've not only achieved these goals but surpassed them, thanks to your hard work and dedication." },
    { id: 21, name: "Uma Thurman", designation: "Risk Analyst", imageUrl:person, subordinates: [], description:"When I first joined this remarkable organization, we were a team fueled by ambition and potential. We had a clear vision—to not only lead in our industry but to set standards of excellence and innovation. Since then, we've not only achieved these goals but surpassed them, thanks to your hard work and dedication." },
    { id: 22, name: "Victor Hugo", designation: "Logistics Coordinator", imageUrl:person, subordinates: [], description:"When I first joined this remarkable organization, we were a team fueled by ambition and potential. We had a clear vision—to not only lead in our industry but to set standards of excellence and innovation. Since then, we've not only achieved these goals but surpassed them, thanks to your hard work and dedication." },
    { id: 23, name: "Walt Disney", designation: "Supply Chain Manager", imageUrl:person, subordinates: [], description:"When I first joined this remarkable organization, we were a team fueled by ambition and potential. We had a clear vision—to not only lead in our industry but to set standards of excellence and innovation. Since then, we've not only achieved these goals but surpassed them, thanks to your hard work and dedication." },
    { id: 24, name: "Xena Warrior", designation: "Recruiter", imageUrl:person, subordinates: [], description:"When I first joined this remarkable organization, we were a team fueled by ambition and potential. We had a clear vision—to not only lead in our industry but to set standards of excellence and innovation. Since then, we've not only achieved these goals but surpassed them, thanks to your hard work and dedication." },
  */ ];
  
const AboutUsPage = () => {
  return <OrganizationHierarchy data={orgData} />;
};
export default AboutUsPage;
