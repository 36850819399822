import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import React, { useState, useEffect } from "react";
import "./BookDemo.css";
import emailjs from "emailjs-com";
import LoadingIndicator from "./LoadingIndicator";

const BookDemo = () => {
  const [productInterest, setProductInterest] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [company, setCompany] = useState("");
  /* const [jobTitle, setJobTitle] = useState(""); */
  const [phoneNumber, setPhoneNumber] = useState("");
  const [howHeard, setHowHeard] = useState("");
  const [country, setCountry] = useState("");
  const [streetAddress, setStreetAddress] = useState("");
  const [addressLine2, setAddressLine2] = useState("");

  const [city, setCity] = useState("");
  const [filteredCities, setFilteredCities] = useState([]);
  const [showDropdown, setShowDropdown] = useState(false);
  const [postalCode, setPostalCode] = useState("");

  const [comments, setComments] = useState("");
  const [otherDetails, setOtherDetails] = useState("");
  const [consent, setConsent] = useState(false);
  const [submitLoading, setSubmitLoading] = useState(false);
  const [countries, setCountries] = useState([]);
  /* const username = "yashraj"; */
  // Geoapify API key
  const apiKey = "b8c8b097b5a5405bb8e5b654c99ad981";

  useEffect(() => {
    const fetchCountries = async () => {
      try {
        const response = await fetch("https://restcountries.com/v3.1/all");
        const data = await response.json();
        const countryList = data
          .map((country) => ({
            code: country.cca2,
            name: country.name.common,
          }))
          .sort((a, b) => a.name.localeCompare(b.name));
        setCountries(countryList);
      } catch (error) {
        console.error("Error fetching countries:", error);
      }
    };

    fetchCountries();
  }, []);
  const handlePhoneChange = (value, data) => {
    setPhoneNumber(value);
    console.log("----------");
    console.log(value);
    console.log("----------");
    setCountry(data.countryCode.toUpperCase());
  };
  const fetchCities = async (query) => {
    console.log("{{{{{{{{{{{{{{{{");
    console.log(query);
    console.log("{{{{{{{{{{{{{{{{");
    try {
      const response = await fetch(
        `https://api.geoapify.com/v1/geocode/autocomplete?text=${query}&apiKey=${apiKey}`
      );
      const data = await response.json();
      console.log("++++++++++++++++++++++++++");
      console.log(data);
      console.log("++++++++++++++++++++++++++");
      // Filter and map the response to get city names
      /* const cityList = data.features.map((feature) => feature.properties.city); */
      const cityList = data.features
        .map((feature) => ({
          city: feature.properties.city,
          postalCode: feature.properties.postcode,
        }))
        .filter(({ city }) => city);
      // Remove duplicate cities
      const uniqueCityList = Array.from(
        new Map(cityList.map((item) => [item.city, item])).values()
      );

      console.log("===============");
      console.log(uniqueCityList);
      console.log("===============");
      setFilteredCities(uniqueCityList);
      setShowDropdown(true);
    } catch (error) {
      console.error("Error fetching cities:", error);
      setFilteredCities([]);
      setShowDropdown(false);
    }
    console.log("???????????????");
    console.log(query);
    console.log("???????????????");
  };
  /* const fetchCities = async (query) => {
    console.log("{{{{{{{{{{{{{{{{");
    console.log(query);
    console.log("{{{{{{{{{{{{{{{{");
    try {
      const response = await fetch(
        `http://api.geonames.org/searchJSON?q=${query}&maxRows=10&username=${username}`
      );
      const data = await response.json();
      const cityList = data.geonames.map((city) => city.name); 
     
      setFilteredCities(cityList);
      setShowDropdown(true);
    } catch (error) {
      console.error("Error fetching cities:", error);
      setFilteredCities([]);
      setShowDropdown(false);
    }
    
    console.log("???????????????");
    console.log(query);
    console.log("???????????????");
  }; */
  const handleCityChange = (e) => {
    console.log("Changed-------------");
    console.log(e.target.value);
    console.log("Changed-------------");
    const inputValue = e.target.value;
    setCity(inputValue);

    if (inputValue.length > 1) {
      fetchCities(inputValue);
    } else {
      setShowDropdown(false);
    }
  };

  /* const handleCityChange = (e) => {
    console.log("Changed-------------");
    console.log(e.target.value);
    console.log("Changed-------------");
    const inputValue = e.target.value;
    setCity(inputValue);

    if (inputValue.length > 1) {
      fetchCities(inputValue);
    } else {
      setShowDropdown(false);
    }
  };
 */
  const handleCitySelect = (selectedCity) => {
    setCity(selectedCity.city);
    setPostalCode(selectedCity.postalCode);
    /* setPostalCode(postcode) */
    setShowDropdown(false);
  };
  const handleSubmit = (event) => {
    setSubmitLoading(true);
    event.preventDefault();

    const templateParams = {
      productInterest,
      firstName,
      lastName,
      email,
      company,
      phoneNumber,
      howHeard,
      country,
      streetAddress,
      addressLine2,
      city,
      postalCode,
      comments,
      otherDetails,
      consent,
    };

    emailjs
      .send(
        "service_rnjoryw",
        "template_43mhon9",
        templateParams,
        "PWmv_gmhD4K0X6eBW"
      )
      .then(
        (response) => {
          console.log("Email sent successfully:", response);
          setSubmitLoading(false);
          alert("Form submitted successfully!");
          resetForm();
        },
        (error) => {
          console.error("Error sending email:", error);
          setSubmitLoading(false);
          alert("Failed to submit form.");
        }
      );
  };
  const resetForm = () => {
    setProductInterest("");
    setFirstName("");
    setLastName("");
    setEmail("");
    setCompany("");
    setPhoneNumber("");
    setHowHeard("");
    setCountry("");
    setStreetAddress("");
    setAddressLine2("");
    setCity("");
    setPostalCode("");
    setComments("");
    setOtherDetails("");
    setConsent(false);
  };
  return (
    <div className="contact-form">
      <div className="form-heads">
        <h1>Contact Us</h1>

        <h4>
          If you would like to learn more or discuss how our solutions can help
          your business grow, please fill out the form below and our experts
          will reach out to you.
          <br />
          <br />
        </h4>
        <h4>
          <b>
            Step into the Future of Smart Healthcare Management — Schedule a
            Demo to Explore Our Innovative Technologies.
          </b>
        </h4>
      </div>
      <form onSubmit={handleSubmit} className="form">
        <div className="columns">
          <label>How can we help you ?</label>
          <select id="myDropdown" 
            value={productInterest}
            onChange={(e) => setProductInterest(e.target.value)}>
            <option value="Select product of your interest">Select product of your interest</option>
            <option value="Sansys Healthcare Product Suite">Sansys Healthcare Product Suite</option>
            <option value="Sansys HIMS">Sansys HIMS</option>
            <option value="Sansys EHR">Sansys EHR</option>
            <option value="Sansys BBMS">Sansys BBMS</option>
            <option value="Sansys Onco Care">Sansys Onco Care</option>
            <option value="Sansys Nursing Care">Sansys Nursing Care</option>
            <option value="Sansys Emergency Care">Sansys Emergency Care</option>
            <option value="Sansys LIMS">Sansys LIMS</option>
            <option value="Sansys BI & Research">Sansys BI & Research</option>
            <option value="Sansys Doctor's App">Sansys Doctor's App</option>
            <option value="Sansys Nursing App">Sansys Nursing App</option>
            <option value="Sansys Patient Portal">Sansys Patient Portal</option>
          </select>
        </div>
        <div className="rows">
          <div className="columns">
            <label htmlFor="firstName">First Name *</label>
            <input
              type="text"
              id="firstName"
              value={firstName}
              onChange={(e) => setFirstName(e.target.value)}
              required
            />
          </div>
          <div className="columns">
            <label htmlFor="lastName">Last Name *</label>
            <input
              type="text"
              id="lastName"
              value={lastName}
              onChange={(e) => setLastName(e.target.value)}
              required
            />
          </div>
        </div>

        <div className="rows">
          <div className="columns">
            <label htmlFor="email">Email Address *</label>
            <input
              type="email"
              id="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
            />
          </div>
          <div className="columns">
            <label htmlFor="company">Company</label>
            <input
              type="text"
              id="company"
              value={company}
              onChange={(e) => setCompany(e.target.value)}
            />
          </div>
        </div>

        <div className="rows">
          <div className="columns">
            <label htmlFor="phoneNumber">Phone Number *</label>
            <PhoneInput
              country={"in"}
              value={phoneNumber}
              onChange={handlePhoneChange}
              inputProps={{
                name: "phone",
                required: true,
                autoFocus: false,
              }}
            />
          </div>
        </div>
        <div className="columns">
          <label htmlFor="howHeard">How you heard about us</label>
          <select
            id="howHeard"
            value={howHeard}
            onChange={(e) => setHowHeard(e.target.value)}
          >
            <option value="none">Select...</option>
            <option value="Advertisement / Press">Advertisement / Press</option>
            <option value="Email">Email</option>
            <option value="Website / Search Engine">
              Website / Search Engine
            </option>
            <option value="Word of Mouth">Word of Mouth</option>
            <option value="Facebook">Facebook</option>
            <option value="Twitter">Twitter</option>
            <option value="LinkedIn">LinkedIn</option>
            <option value="Webinar">Webinar</option>
            <option value="Tradeshow">Tradeshow</option>
            <option value="Other (specify)">Other (specify)</option>
          </select>
          {howHeard === "Other (specify)" && (
            <textarea
              id="otherDetails"
              value={otherDetails}
              onChange={(e) => setOtherDetails(e.target.value)}
              placeholder="Please specify how you heard about us"
            />
          )}
        </div>
        <div className="columns">
          <label htmlFor="country">Country *</label>
          <select
            id="country"
            value={country}
            onChange={(e) => setCountry(e.target.value)}
            required
          >
            <option value="">Select option...</option>
            {countries.map((c) => (
              <option key={c.code} value={c.name}>
                {c.name}
              </option>
            ))}
          </select>
        </div>
        <div className="columns">
          <label htmlFor="streetAddress">Address Line 1</label>
          <input
            type="text"
            id="streetAddress"
            value={streetAddress}
            onChange={(e) => setStreetAddress(e.target.value)}
          />
        </div>
        <div className="columns">
          <label htmlFor="addressLine2">Address Line 2</label>
          <input
            type="text"
            id="addressLine2"
            value={addressLine2}
            onChange={(e) => setAddressLine2(e.target.value)}
          />
        </div>
        <div className="columns">
          <label htmlFor="city">City/Town</label>
          <input
            type="text"
            id="city"
            value={city}
            onChange={handleCityChange}
          />
          {showDropdown && filteredCities.length > 0 && (
            <ul className="dropdown">
              {filteredCities.map((c, index) => (
                <li key={index} onClick={() => handleCitySelect(c)}>
                  {c.city}
                </li>
              ))}
            </ul>
          )}
        </div>
        <div className="columns">
          <label htmlFor="postalCode">Postal Code</label>
          <input
            type="text"
            id="postalCode"
            value={postalCode}
            onChange={(e) => setPostalCode(e.target.value)}
          />
        </div>
        <div>
          <label htmlFor="comments">Comments</label>
          <textarea
            id="comments"
            value={comments}
            onChange={(e) => setComments(e.target.value)}
          />
        </div>
        <div style={{ display: "flex", columnGap: "8px" }}>
          <input
            type="checkbox"
            id="consent"
            checked={consent}
            onChange={(e) => setConsent(e.target.checked)}
            style={{ height: "16px", width: "16px" }}
          />
          <label htmlFor="consent">
            I consent to receiving marketing communications from Sansys
            Informatics by telephone, email, SMS and physical mail. I understand
            I will be able to withdraw my consent, change the method(s) used for
            communication or unsubscribe at any time by clicking here. Please
            read Sansys Informatics privacy notice to learn more how Sansys
            Informatics will process your personal data.
          </label>
        </div>
        <div className="btn">
          {submitLoading ? (
            <LoadingIndicator />
          ) : (
            <button type="submit">Submit</button>
          )}
        </div>
      </form>
    </div>
  );
};

export default BookDemo;
