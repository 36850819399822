import { useState } from "react";
import visImg from "../images/workwus.jpg";
import "./WorkWithUs.css";
const WorkWithUs = () => {
  const [isOpeningnsOpen, setIsOpeningsOpen] = useState(false);
  const [isInternsOpen, setIsInternsOpen] = useState(false);
  const toggleOpeningsListing = () => {
    setIsOpeningsOpen(!isOpeningnsOpen);
  };
  const toggleIntenrsListing = () => {
    setIsInternsOpen(!isInternsOpen);
  };
  return (
    <div style={{ marginTop: "8px" }}>
      <div className="product-intro">Join the Sansys Informatics Team</div>
      <div className="tile-vm">
        <div className="vm-content">
          <div className="vm-desc">
            At Sansys Informatics Pvt. Ltd., we believe in pushing the
            boundaries of healthcare technology to create solutions that truly
            make a difference. If you're passionate about innovation, eager to
            contribute to the future of healthcare, and ready to be part of a
            dynamic and growing team, we'd love to hear from you.
            {/* {description.map((desc, i) => (
            <p key={i}>{desc}</p>
          ))} */}
          </div>
          <div className="values">
            <h2>
              <b>Our Values</b>
            </h2>
            <div className="jobs-listing">
              <li>
                Excellence: We strive for the highest standards in everything we
                do.
              </li>
              <li>Integrity: We believe in doing the right thing, always.</li>
              <li>
                Innovation: We are driven by curiosity and a passion for
                creating better solutions.
              </li>
              <li>
                Collaboration: We believe that teamwork is the key to success.
              </li>
            </div>
          </div>
          <div className="opp">
            <h2>
              <b>Explore Opportunities</b>
            </h2>
            Ready to make a difference? Explore our current openings and find
            out how you can be a part of the Sansys family. Whether you're a
            seasoned professional or just starting your career, we offer roles
            that challenge and inspire
          </div>
          <div className="openings">
            <div className="openings-head" onClick={toggleOpeningsListing}>
              Openings&nbsp;&nbsp;<b> &#8681;</b>
            </div>
            {isOpeningnsOpen && (
              <div className="jobs-listing">
                {/* <li>Tech Support Lead </li>
                <li>Sr. Development Head </li> */}
              </div>
            )}
          </div>
          <div className="internships">
            <div className="internships-head" onClick={toggleIntenrsListing}>
              Internships&nbsp;&nbsp;<b> &#8681;</b>
            </div>
            {isInternsOpen && (<div className="jobs-listing">
              {/* <li>InternTech Support Lead </li>
              <li>Intern Development operations </li> */}
            </div>)}
          </div>
        </div>
        <div className="vm-overlay"></div>
        <div className="vm-image">
          <img src={visImg} alt={""} />
        </div>
      </div>
    </div>
  );
};

export default WorkWithUs;
