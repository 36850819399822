import React, { useEffect, useContext } from "react"; /* 
import { Link } from "react-router-dom"; */
import size from "../images/icons/size.png";
import speed from "../images/icons/speed.png";
import representative from "../images/icons/india.png";
import integrated from "../images/icons/settings.png";
import diverse from "../images/icons/diversity.png";
import { motion } from "framer-motion";
import "./Product.css";
import { useLocation } from "react-router-dom";
import { ShowContext } from "./ShowContext";

const ServiceCard = ({ title, subtitle, imageUrl, link }) => {
  const { setIsServiceVisible, setShow } = useContext(ShowContext);
  const handleCardClose = () => {
    setIsServiceVisible(false);
    setShow(false);
  };
  // Inside your CustomNavbar component:
  const location = useLocation();
  const handleNavigation = (path, hash = "") => {
    /* vis(false); */
    // Navigate to the path
    window.location.href = path + hash;
    handleCardClose();
    // Scroll to the section if already on the ProductPage
    if (location.pathname === path && hash) {
      const element = document.getElementById(hash.substring(1));
      if (element) {
        element.scrollIntoView({ behavior: "smooth" });
      }
    }
  };
  return (
    <motion.div
      className="product-card"
      initial={{ opacity: 0, y: 20 }}
      animate={{ opacity: 1, y: 0 }}
      whileHover={{ scale: 1.05 }}
      whileTap={{ scale: 0.95 }}
      transition={{ duration: 0.3 }}
      onClick={() => handleNavigation("/service", `${link}`)}
    >
      {/* <div className="product-card-image">
        <img src={imageUrl} alt="" />
      </div> */}
      <div className="product-card-content">
        <h2 className="product-card-title">{title}</h2>
        <p className="product-card-subtitle">{subtitle}</p>
      </div>
    </motion.div>
  );
};

const Service = ({ vis }) => {
  const services = [
    {
      title: "Application Implementation Services",
      subtitle: "Seamless Deployments, Unmatched Efficiency",
      link: "#sansys-applicatin-implementation",
      bgImg: size,
    },
    {
      title: "Clinical Transformation",
      subtitle: "Shaping the future of healthcare through our robust systems",
      link: "#sansys-clinical-transformation",
      bgImg: speed,
    },
    {
      title: "Application Integration Services",
      subtitle: "Connecting Systems, Enhancing Care",
      link: "#sansys-application-integration",
      bgImg: representative,
    },
    {
      title: "Data Migration Services",
      subtitle: "Securely Moving Data, Transforming Futures",
      link: "#sansys-data-migration",
      bgImg: integrated,
    },

    {
      title: "IoT Integration Services",
      subtitle: "Smart Connections for Smarter Healthcare",
      link: "#sansys-iot-integration",
      bgImg: diverse,
    },
    {
      title: "Equipment Interfacing",
      subtitle: "Bridging Technology, Empowering Care",
      link: "#sansys-equipment-interfacing",
      bgImg: diverse,
    },
    {
      title: "Mobile Applications",
      subtitle: "Healthcare on the Go, Always at Your Fingertips",
      link: "#sansys-mobile-apps",
      bgImg: diverse,
    },
    {
      title: "Product Training Services",
      subtitle: "Empowering Users, Maximizing Potential",
      link: "#sansys-training",
      bgImg: diverse,
    },
    {
      title: "24x7 Expert Support Services",
      subtitle: "Always Here, Always Expert",
      link: "#sansys-support",
      bgImg: diverse,
    },
  ];

  useEffect(() => {
    console.log("Benefits Mounted");
  }, []);

  return (
    <>
      {/* <motion.div
            className="sol-head"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            transition={{ duration: 0.5 }}
          >
            <div className="h-line"></div>
            <h1>Key Benefits</h1>
            <div className="h-line"></div>
          </motion.div> */}
      <motion.div
        className="productsPage"
        initial={{ y: "-120%", opacity: 0 }}
        animate={{ opacity: 1, y: "0px" }}
        exit={{ opacity: 0, visibility: "hidden" }}
        transition={{ duration: 0.4 }}
      >
        {services.map((slider, index) => (
          <ServiceCard
            key={index}
            title={slider.title}
            subtitle={slider.subtitle}
            imageUrl={slider.bgImg}
            link={slider.link}
            /* vis={vis} */
          />
        ))}
      </motion.div>
    </>
  );
};
export default Service;
