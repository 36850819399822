import React from "react";
import Rating from "./Rating";
import "./Accolades.css";
import cert1 from "../images/accolades/cert1.jpg";
import cert2 from "../images/accolades/cert2.jpg";
import cert3 from "../images/accolades/cert3_new.jpg";
import cert4 from "../images/accolades/cert4_new.jpg";
import cert5 from "../images/accolades/cert5_new.jpg";
import cert6 from "../images/accolades/cert6_new.jpg";
import cert7 from "../images/accolades/cert7_new.jpg";
/* import cert8 from "../images/accolades/cert8_new.jpg"; */
import cert9 from "../images/accolades/cert9_new.jpg";
import cert10 from "../images/accolades/cert10_new.jpg";
import cert11 from "../images/accolades/cert11_new.jpg";
import cert12 from "../images/accolades/cert12_new.png";
import cert13 from "../images/accolades/cert13_new.png";

const TileAccolades = ({ title, descriptions, rating, images }) => {
  return (
    <div className="accolades-tile">
      <div className="accolades-overlay"></div>
      <div className="accolades-logo">
        <img src={images} alt={title} />
      </div>
      <div className="accolades-content">
        <div className="accolades-subtitle">
          {descriptions.map((description, i) => (
            <h4 key={i}>
              <b>{description}</b>
            </h4>
          ))}
        </div>
        <div>
          <Rating rating={rating} />
        </div>
        Honoured By : <h3 className="accolades-title">{title}</h3>
      </div>
    </div>
  );
};

const accolades = [
  {
    title: "AIIMS, New Delhi",
    description: [
      "Certification of Appreciation for cost effective implementation of Sansys EHR across the whole AIIMS Delhi.",
    ],
    image: cert1, // maxLogo
    rating: 5,
  },
  {
    title: "AIIMS, New Delhi",
    description: [
      "Certificate of Appreciation to the CEO for Leading the Successful Implementation of Sansys EHR, Elevating Healthcare Excellence at AIIMS Delhi.",
    ],
    image: cert2, // aimsLogo,
    rating: 5,
  },
  {
    title: "AIIMS, New Delhi",
    description: [
      "Appreciation for the Transformative Implementation of Sansys EHR, Advancing Patient Care Excellence at AIIMS Delhi",
    ],
    image: cert3, // maxLogo
    rating: 5,
  },
  {
    title: "AIIMS, New Delhi",
    description: [
      "Appreciation for the Transformative Implementation of Sansys EHR, Advancing Patient Care Excellence at AIIMS Delhi.",
    ],
    image: cert4, // aimsLogo,
    rating: 5,
  },
  {
    title: "RGCI, New Delhi",
    description: [
      "Appreciation for the Pioneering Integration of Sansys BBMS, eConsent Management, and CSSD, Advancing Healthcare Standards at Rajiv Gandhi Cancer Institute & Research Centre, Rohini, New Delhi.",
    ],
    image: cert5, // maxLogo
    rating: 4,
  },
  {
    title: "JPNATC, AIIMS ",
    description: [
      "Appreciation for the Express Implementation of Sansys BBMS Module, advancing Patient Care Excellence at JPNATC, AIIMS New Delhi. ",
    ],
    image: cert6, // aimsLogo,
    rating: 5,
  },
  {
    title: "Indian Cancer Society, New Delhi",
    description: [
      "Appreciation for Delivering a Customized Implementation of Sansys HIMS and EHR, Including a Specially Designed Preventive Oncology Module for Indian Cancer Society, Cancer Treatment Center, New Delhi.",
    ],
    image: cert7, // maxLogo
    rating: 5,
  },
  /* {
    title: "All India Institute of Medical Sciences, New Delhi",
    description: [
      "Certification of Appreciation for cost effective implementation of Sansys EHR across the whole AIIMS Delhi.",
    ],
    image: cert8, // aimsLogo,
    rating: 4.5,
  }, */
  {
    title: "SSPHPGTI, New Delhi",
    description: [
      "Appreciation for the Successful Implementation of Sansys HIMS and EHR, Elevating Pediatric Care and Operational Excellence at  Super Speciality Paediatric Hospital & Post Graduate Teaching Institute, Noida.",
    ],
    image: cert9, // maxLogo
    rating: 4,
  },
  {
    title: "Max Healthcare Group",
    description: [
      "Appreciation for Excellently Orchestrating the Pivotal Integration of Sansys EHR, BCMA, Clinical Pharmacy, BBMS, and NDRS, Defining New Standards of Excellence Across All Max Healthcare Hospital Locations.",
    ],
    image: cert10, // aimsLogo,
    rating: 4.5,
  },
  {
    title: "Lok Nayak Govt. Hospital, Delhi",
    description: [
      "Award for Superior Implementation of Sansys EHR and Sansys HIMS, Enhancing Patient Registration Efficiency at Lok Nayak Hospital, Government of NCT of Delhi",
    ],
    image: cert11, // maxLogo
    rating: 5,
  },
  {
    title: "BLK-MAX",
    description: [
      "Recognition for the Pioneering Implementation of Sansys BBMS, Streamlining Blood Bank Operations and Elevating Safety Standards at BLK-Max Super Speciality Hospital.",
    ],
    image: cert12, // maxLogo
    rating: 5,
  },
  {
    title: "KIMS, Kerala",
    description: [
      "Appreciation for Outstanding Implementation of Sansys BBMS, Revolutionizing Blood Bank Operations and Ensuring Safe Blood Management at Kerala Institute of Medical Sciences (KIMS)",
    ],
    image: cert13, // maxLogo
    rating: 5,
  },
];

const Accolades = () => {
  return (
    <div className="accolades-page">
      <h2 style={{ textAlign: "center" }}>
        Recognizing Excellence: Our Journey of Achievements and Accolades
      </h2>
      {accolades.map((client, index) => (
        <TileAccolades
          key={index}
          title={client.title}
          descriptions={client.description}
          images={client.image}
          rating={client.rating}
          index={index}
        />
      ))}
    </div>
  );
};

export default Accolades;

/* export default TileAccolades; */
