import React from "react";
import { motion } from "framer-motion";
import CountUp from "react-countup";
import "./Statistics.css";

const SectionComponent = ({ prefix, suffix, subtitle }) => {
  return (
    <div className="section">
      <h1>
        <CountUp start={0} end={prefix} suffix="" duration={5}/>
        {suffix}
      </h1>
      <p>{subtitle}</p>
    </div>
  );
};

const Statistics = () => {
  const sections = [
    { prefix: 25, suffix: "+", subtitle: "Hospitals" },
    { prefix: 750, suffix: "+", subtitle: "Clinics" },
    { prefix: 7, suffix: "K+", subtitle: "Beds" },
    { prefix: 12, suffix: "K+", subtitle: "Clinicians" },
    { prefix: 13, suffix: "M+", subtitle: "Appointments/year" },
    { prefix: 1, suffix: "M+", subtitle: "Admissions/year" },
  ];

  return (
    <motion.div
      className="parent" /* 
      initial={{  y: "120%",  opacity: 0 }}
      animate={{  y: "0%",   opacity: 1 }}
      transition={{ duration: 1.2 , delay: 2.5  }} */
    >
      <div className="statistics">
        {sections.map((section, index) => (
          <SectionComponent
            key={index}
            prefix={section.prefix}
            suffix={section.suffix}
            subtitle={section.subtitle}
          />
        ))}
      </div>
    </motion.div>
  );
};

export default Statistics;
