import React, { useEffect, useContext } from "react";
/* import { useState } from "react"; */ /* 
import { Link } from "react-router-dom"; */
import size from "../images/icons/size.png";
import speed from "../images/icons/speed.png";
import representative from "../images/icons/india.png";
import integrated from "../images/icons/settings.png";
import diverse from "../images/icons/diversity.png";
import { motion } from "framer-motion";
import "./Product.css";
import { useLocation } from "react-router-dom";
import { ShowContext } from "./ShowContext";
/* 
const [show, setShow] = useState(true); */

const ProductCard = ({ title, subtitle, imageUrl, link }) => {
  const {
    setShow,
    isProductDropdownVisible,
    setIsProductVisible,
  } = useContext(ShowContext);
  const handleCardClose = () => {
    console.log("Inside handleCardClose");
    setIsProductVisible(false);
    console.log("[[[[[[[[[[[[[[[first]]]]]]]]]]]]]]]");
    console.log(isProductDropdownVisible);
    setShow(false);
    console.log(isProductDropdownVisible);
    console.log("[[[[[[[[[[[[[[[first]]]]]]]]]]]]]]]");
  };
  /* const [show, setShow] = useState(); */
  // Inside your CustomNavbar component:
  const location = useLocation();

  const handleNavigation = (path, hash = "") => {
    /* setShow(false); */
    /* vis(false); */
    // Navigate to the path
    /* console.log("Clicked on item" + hash);
    console.log(vis);
    handleCardClose(vis); */
    window.location.href = path + hash;
    handleCardClose();

    // Scroll to the section if already on the ProductPage
    if (location.pathname === path && hash) {
      const element = document.getElementById(hash.substring(1));
      if (element) {
        element.scrollIntoView({ behavior: "smooth" });
      }
    }
  };
  return (
    <>
      <motion.div
        className="product-card"
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        whileHover={{ scale: 1.05 }}
        whileTap={{ scale: 0.95 }}
        transition={{ duration: 0.3 }}
        onClick={() => handleNavigation("/product", `${link}`)}
      >
        <div className="product-card-content">
          <h2 className="product-card-title">{title}</h2>
          <p className="product-card-subtitle">{subtitle}</p>
        </div>
      </motion.div>
    </>
  );
};

const Product = () => {
  const products = [
    {
      title: "Sansys HIMS",
      subtitle:
        "Enhancing Hospital Management with Comprehensive Patient Records, Seamless Workflow Integration, and Advanced Analytics.",
      bgImg: size,
      link: "#sansys-hims",
    },
    {
      title: "Sansys EHR",
      subtitle:
        "Improved Healthcare with Secure, Accessible, and Comprehensive Real-time Electronic Health Records.",
      bgImg: speed,
      link: "#sansys-ehr",
    },
    {
      title: "Sansys Blood Bank Management System",
      subtitle: "Ensuring Safe, Efficient, and Reliable Blood Management.",
      bgImg: representative,
      link: "#sansys-bbms",
    },
    {
      title: "Sansys Onco Care",
      subtitle: "Precision Oncology Solutions for Enhanced Patient Care.",
      bgImg: integrated,
      link: "#sansys-onco",
    },
    {
      title: "Sansys Nursing Care",
      subtitle:
        "Elevating Patient Care with Streamlined Workflows and Real-time Data.",
      bgImg: integrated,
      link: "#sansys-nurCare",
    },
    {
      title: "Sansys Emergency Care",
      subtitle:
        "Elevating Patient Care with Streamlined Workflows and Real-time Data.",
      bgImg: integrated,
      link: "#sansys-emerCare",
    },
    {
      title: "Sansys Laboratory Information Management System - (SLIM™)",
      subtitle:
        "Optimizing Laboratory Operations with Accurate, Efficient, and Integrated Data Management.",
      bgImg: diverse,
      link: "#sansys-lims",
    },
    {
      title: "Sansys BI & Research",
      subtitle:
        "Driving Insights and Innovation with Powerful Data Analytics and Research Tools.",
      bgImg: diverse,
      link: "#sansys-bi",
    },
    {
      title: "Sansys Doctor's App",
      subtitle:
        "Connecting Physicians with Patient Data Anytime, Anywhere for Better Care.",
      bgImg: diverse,
      link: "#sansys-docApp",
    },
    {
      title: "Sansys Nursing App",
      subtitle:
        "Empowering Nurses with Mobile Tools for Enhanced Patient Interaction and Care.",
      bgImg: diverse,
      link: "#sansys-nurApp",
    },
    {
      title: "Sansys Patient Portal App",
      subtitle:
        "Engaging Patients with Secure Access to Health Information and Services.",
      bgImg: diverse,
      link: "#sansys-patPortApp",
    },
  ];
  useEffect(() => {
    console.log("Benefits Mounted");
  }, []);

  return (
    <>
      <motion.div
        className="productsPage"
        initial={{ y: "-120%", opacity: 0 }}
        animate={{ opacity: 1, y: "0px" }}
        exit={{ opacity: 0, visibility: "hidden" }}
        transition={{ duration: 0.4 }}
      >
        {products.map((slider, index) => (
          <ProductCard
            key={index}
            title={slider.title}
            subtitle={slider.subtitle}
            imageUrl={slider.bgImg}
            link={slider.link}
          />
        ))}
      </motion.div>
    </>
  );
};
export default Product;
