import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import "./App.css";
import Navbar from "./pages/navbar";
import Footer from "./pages/footer";
import Home from "./pages/Home";
import Clients from "./pages/Clients";
import BookDemo from "./pages/BookDemo";
import ServicePage from "./pages/ServicePage";
import ProductPage from "./pages/ProductPage";
import AboutUsPage from "./pages/AboutUsPage";
import OurVision from "./pages/OurVision";
import OurTeam from "./pages/OurTeam";
import WorkWithUs from "./pages/WorkWithUs";
import Events from "./pages/Events";
import Brochures from "./pages/Brochures";
import Accolades from "./pages/Accolades";
import AboutUsMobile from "./pages/AboutUsMobile";
import Product from "./pages/Product";
import Service from "./pages/Service";
import MemberDetail from "./pages/MemberDetail";
import ScrollToTop from "./pages/ScrollToTop";
import { ShowProvider } from "./pages/ShowContext";

const App = () => {
  return (
    <>
      <Router>
        <ShowProvider>
          <div className="nav-top">
            <Navbar />
          </div>
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/product" element={<ProductPage />} />
            <Route path="/productmob" element={<Product />} />
            <Route path="/service" element={<ServicePage />} />
            <Route path="/servicemob" element={<Service />} />
            <Route path="/clients" element={<Clients />} />
            <Route path="/about" element={<AboutUsPage />} />
            <Route path="/aboutmob" element={<AboutUsMobile />} />
            <Route path="/accolades" element={<Accolades />} />
            <Route path="/book-demo" element={<BookDemo />} />

            <Route path="/our-vision" element={<OurVision />} />
            <Route path="/our-team" element={<OurTeam />} />
            <Route path="/our-team/:name" element={<MemberDetail />} />
            <Route path="/work-with-us" element={<WorkWithUs />} />
            <Route path="/events" element={<Events />} />
            <Route path="/brochures" element={<Brochures />} />
          </Routes>
          <Footer />
          <ScrollToTop />
        </ShowProvider>
      </Router>
    </>
  );
};

export default App;
