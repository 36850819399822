import React from "react";
import { useNavigate } from "react-router-dom";
import person from "../images/person.png";
import tick from "../images/tick.jpg";
import gauravSharma from "../images/team/gaurav_sharma.jpg";
import santoshMishra from "../images/team/santosh_mishra.jpg";
import anupmaKashyap from "../images/team/anupmaKashyap.jpg";
import hansrajThakur from "../images/team/hansrajThakur.jpg";
import sandeepGupta from "../images/team/sandeepGupta.jpg";
import deepakAgarwal from "../images/team/deepakAgarwal.jpg";
import jpDwivedi from "../images/team/jpDwivedi.jpg";

import "./OurTeam.css";

/* const OurTeamCard = ({ desc }) => {
  return (
    <div className="description-div">
      {desc.map((description, i) => (
        <p key={i}>{description}</p>
      ))}
    </div>
  );
}; */
const leaders = [
  {
    name: "mr-santosh-mishra",
    title: "Mr. Santosh Mishra",
    designation: "CEO",
    description: [
      "	Santosh Mishra serves as the Chief Executive Officer and the Founder of Sansys Informatics. His passion for the development of world class products and use of latest technology is a driving force behind Sansys Informatics. He started the company with a vision to make EHR a very affordable entity for the Indian Healthcare Industry. With more than a decade of experience in the software industry and having worked for multiple healthcare domains, he has immense knowledge of the EHR Technologies and so much so that he has single handedly implemented EHR in such a way that it has become a revolution in the biggest public sector hospitals AIIMS Delhi and AIIMS Bhopal.",
      "	Prior to this he was leading the division for EHR Integration with MobileCare application at Agnity. He was a key member of Implementation and Integration of WorldVistA EHR for Rajeev Gandhi Cancer Institute, New Delhi, Military Hospital and Cardiothoracic Center, Pune. He has also worked as a Subject Matter Expert for VistA Implementation in Max Hospital.",
    ],
    logoImg: santoshMishra,

    rating: 4.5,
  },
  {
    name: "dr-syed-tirmizi",
    title: "Dr. Syed Tirmizi (MD, CPHQ)",
    designation: "Director",
    description: [
      "	Dr. Syed Tirmizi is an internationally recognized medical informatician Since 1990, he worked at a number of Veterans Health Administration (VHA) hospitals in America as Chief of Medicine & Ambulatory Care and later as Senior Medical Informatician at the VHA headquarters in Washington, DC.",
      "	He collaborated with a number of leading University Hospitals to share the VHA medical informatics best practices including VistA-CPRS. He was also the Program Director of the Medical Informatics Fellowships offered at eight VHA Hospitals affiliated with leading Universities and represented the VHA at the National Library of Medicine Medical Informatics annual conferences.",
      "	Dr. Tirmizi was a keynote speaker at a number of international conferences including in the European Union, Middle-East and the Asia Pacific Region. He presented the VHA developed EHR called VistA-CPRS to a number of international delegations interested in adopting this open source EHR in their countries. He supervised the deployment of VistA-CPRS at Max Healthcare network and Rajiv Gandhi Cancer Hospitals in New Delhi. Max Hospitals were designated by HIMSS as first Stage 6 implementation in India back in 2012.Dr. Tirmizi was a keynote speaker at a number of international conferences including in the European Union, Middle-East and the Asia Pacific Region. He presented the VHA developed EHR called VistA-CPRS to a number of international delegations interested in adopting this open source EHR in their countries. He supervised the deployment of VistA-CPRS at Max Healthcare network and Rajiv Gandhi Cancer Hospitals in India. Back in 2012, Max Hospitals were designated by HIMSS as Stage 6 implementation.",
      " Dr. Tirmizi promoted a culture of Patient Safety internationally as Senior VP at Quantros – a Patient Safety Organization and later as a consultant with Pascal metrics – another Patient Safety Organization.",
      " Dr. Tirmizi is particularly interested in use of Artificial Intelligence in Healthcare to enable better, safer and efficient care. Recently he participated in the US Government Summit on AI in Healthcare and reviewed the AI roll-out strategy.",
      " Dr. Tirmizi was faculty at Texas Tech University of Health Sciences, Uniformed Services University of Health Sciences and George Mason University. He developed online Health Informatics courses on EMR, Clinical Decision Support, Change Management etc.",
    ],
    logoImg: person,

    rating: 4.5,
  },

  {
    name: "mr-dinesh-samudra",
    title: "Mr. Dinesh Samudra",
    designation: "Principal Consultant -Sales & Marketing",
    description: [
      "	Dinesh Samudra truly believes in transforming the way care is delivered by digitizing healthcare solutions. Highly driven by his passion, he embarked on a journey to develop and design healthcare solutions that would and Co-founder and CEO of Multiple Healthcare IT Organisations, improve care management by providing an innovative and patient-centric SaaS-based platform for better collaboration between healthcare providers and consumers.",
      "	With 32 years of experience in Information Technology and 21 years in Healthcare Dinesh brings to the forefront, his global expertise and knowledge in Healthcare and Health-Tech. His forte lies in conceptualising and building software solutions by mapping procedures and processes of healthcare institutions. In order to maintain service consistency, Dinesh has managed many complex operational arenas and helped healthcare organisations keep pace with medical advancements.",
      " For more than 21 years, Mr. Dinesh provided healthcare solutions to large companies and governments globally. He has worked with hospital leaders to help educate the Board about the complexities, risks, the intricacies of delivering the highest quality of care using the latest technology. He has been consulting with various medical technology services, organisations and health care leaders to discover advancements that reshape and improve caregiving practices and policies. Successfully lead individual departments, functions, projects, partnerships and service lines in Healthcare businesses.",
    ],
    logoImg: person,

    rating: 4.5,
  },
  {
    name: "mr-uday-pratap",
    title: "Mr. Uday Pratap",
    designation: "Director-Implementation",
    description: [
      "As Vice President of Product Management, Uday Pratap is responsible for the product strategy, direction setting, and channel partner development for Sansys Informatics. In addition, he is responsible for the expansion of Services business in the healthcare domain.",
      "With more than a decade of experience in the software industry and worked for healthcare domains ,Uday has vast knowledge of EHR and he played a vital role in many big implementation like Rajeev Gandhi Cancer Hospital,Max Group of hospital.He also worked for Dell as a SME for CPRS and Radiology. He worked on best EMR in market like Epic and VistA.He is certified in EPIC Ambulatory.",
    ],
    logoImg: person,

    rating: 4.5,
  },
  {
    name: "mr-uday-pratap",
    title: "Mr. Ratneshwar Mishra",
    designation: "Director-Strategy",
    description: [
      "As Vice President of Product Management, Uday Pratap is responsible for the product strategy, direction setting, and channel partner development for Sansys Informatics. In addition, he is responsible for the expansion of Services business in the healthcare domain.",
      "With more than a decade of experience in the software industry and worked for healthcare domains ,Uday has vast knowledge of EHR and he played a vital role in many big implementation like Rajeev Gandhi Cancer Hospital,Max Group of hospital.He also worked for Dell as a SME for CPRS and Radiology. He worked on best EMR in market like Epic and VistA.He is certified in EPIC Ambulatory.",
    ],
    logoImg: person,

    rating: 4.5,
  },
  {
    name: "mr-uday-pratap",
    title: "Mrs Anupma Kashyap",
    designation: "Director-Finance/ HR",
    description: [
      "As Vice President of Product Management, Uday Pratap is responsible for the product strategy, direction setting, and channel partner development for Sansys Informatics. In addition, he is responsible for the expansion of Services business in the healthcare domain.",
      "With more than a decade of experience in the software industry and worked for healthcare domains ,Uday has vast knowledge of EHR and he played a vital role in many big implementation like Rajeev Gandhi Cancer Hospital,Max Group of hospital.He also worked for Dell as a SME for CPRS and Radiology. He worked on best EMR in market like Epic and VistA.He is certified in EPIC Ambulatory.",
    ],
    logoImg: anupmaKashyap,

    rating: 4.5,
  },
  {
    name: "dr-satbir-singh-negi",
    title: "Dr. Satbir Singh Negi",
    designation: "Consultant",
    description: [
      "	Dr. Satbir Singh Negi serves as the Consultant - Corporate Strategy on the Management Board and plays an important role is the direction and vision of the company's growth. He comes with a young experience of 12 years in healthcare domain including clinical, technical and analytics, having worked with technology giants like Oracle, Xavient etc",
      "	Having successfully implemented HMIS and EMR in India, China and USA, now he is focused on the next generation healthcare management by smart and technologically connected and integrated systems on a Global scale.",
    ],
    logoImg: person,

    rating: 4.5,
  },
  {
    name: "dr-satbir-singh-negi",
    title: "Mr Mukesh Mishra",
    designation: "VP-Implementation",
    description: [
      "	Dr. Satbir Singh Negi serves as the Consultant - Corporate Strategy on the Management Board and plays an important role is the direction and vision of the company's growth. He comes with a young experience of 12 years in healthcare domain including clinical, technical and analytics, having worked with technology giants like Oracle, Xavient etc",
      "	Having successfully implemented HMIS and EMR in India, China and USA, now he is focused on the next generation healthcare management by smart and technologically connected and integrated systems on a Global scale.",
    ],
    logoImg: person,

    rating: 4.5,
  },
  {
    name: "dr-satbir-singh-negi",
    title: "Mr Hansraj Thakur",
    designation: "VP-Implementation",
    description: [
      "	Dr. Satbir Singh Negi serves as the Consultant - Corporate Strategy on the Management Board and plays an important role is the direction and vision of the company's growth. He comes with a young experience of 12 years in healthcare domain including clinical, technical and analytics, having worked with technology giants like Oracle, Xavient etc",
      "	Having successfully implemented HMIS and EMR in India, China and USA, now he is focused on the next generation healthcare management by smart and technologically connected and integrated systems on a Global scale.",
    ],
    logoImg: hansrajThakur,

    rating: 4.5,
  },
  {
    name: "mr-gaurav-sharma",
    title: "Mr Gaurav Sharma",
    designation: "VP-Technology",
    description: [
      "	Dr. Satbir Singh Negi serves as the Consultant - Corporate Strategy on the Management Board and plays an important role is the direction and vision of the company's growth. He comes with a young experience of 12 years in healthcare domain including clinical, technical and analytics, having worked with technology giants like Oracle, Xavient etc",
      "	Having successfully implemented HMIS and EMR in India, China and USA, now he is focused on the next generation healthcare management by smart and technologically connected and integrated systems on a Global scale.",
    ],
    logoImg: gauravSharma,

    rating: 4.5,
  },
  {
    name: "dr-satbir-singh-negi",
    title: "Mr Narendra Rathor",
    designation: "VP-Technology",
    description: [
      "	Dr. Satbir Singh Negi serves as the Consultant - Corporate Strategy on the Management Board and plays an important role is the direction and vision of the company's growth. He comes with a young experience of 12 years in healthcare domain including clinical, technical and analytics, having worked with technology giants like Oracle, Xavient etc",
      "	Having successfully implemented HMIS and EMR in India, China and USA, now he is focused on the next generation healthcare management by smart and technologically connected and integrated systems on a Global scale.",
    ],
    logoImg: person,

    rating: 4.5,
  },
  {
    name: "dr-satbir-singh-negi",
    title: "Mr Ahish Pandey",
    designation: "AVP-Technology",
    description: [
      "	Dr. Satbir Singh Negi serves as the Consultant - Corporate Strategy on the Management Board and plays an important role is the direction and vision of the company's growth. He comes with a young experience of 12 years in healthcare domain including clinical, technical and analytics, having worked with technology giants like Oracle, Xavient etc",
      "	Having successfully implemented HMIS and EMR in India, China and USA, now he is focused on the next generation healthcare management by smart and technologically connected and integrated systems on a Global scale.",
    ],
    logoImg: person,

    rating: 4.5,
  },
];

/* const leaders = [
  {
    name: "mr-santosh-mishra",
    title: "Mr. Santosh Mishra",
    designation: "CEO",
    description: [
      "	Santosh Mishra serves as the Chief Executive Officer and the Founder of Sansys Informatics. His passion for the development of world class products and use of latest technology is a driving force behind Sansys Informatics. He started the company with a vision to make EHR a very affordable entity for the Indian Healthcare Industry. With more than a decade of experience in the software industry and having worked for multiple healthcare domains, he has immense knowledge of the EHR Technologies and so much so that he has single handedly implemented EHR in such a way that it has become a revolution in the biggest public sector hospitals AIIMS Delhi and AIIMS Bhopal.",
      "	Prior to this he was leading the division for EHR Integration with MobileCare application at Agnity. He was a key member of Implementation and Integration of WorldVistA EHR for Rajeev Gandhi Cancer Institute, New Delhi, Military Hospital and Cardiothoracic Center, Pune. He has also worked as a Subject Matter Expert for VistA Implementation in Max Hospital.",
    ],
    logoImg: person,

    rating: 4.5,
  },
  {
    name: "dr-syed-tirmizi",
    title: "Dr. Syed Tirmizi (MD, CPHQ)",
    designation: "Director",
    description: [
      "	Dr. Syed Tirmizi is an internationally recognized medical informatician Since 1990, he worked at a number of Veterans Health Administration (VHA) hospitals in America as Chief of Medicine & Ambulatory Care and later as Senior Medical Informatician at the VHA headquarters in Washington, DC.",
      "	He collaborated with a number of leading University Hospitals to share the VHA medical informatics best practices including VistA-CPRS. He was also the Program Director of the Medical Informatics Fellowships offered at eight VHA Hospitals affiliated with leading Universities and represented the VHA at the National Library of Medicine Medical Informatics annual conferences.",
      "	Dr. Tirmizi was a keynote speaker at a number of international conferences including in the European Union, Middle-East and the Asia Pacific Region. He presented the VHA developed EHR called VistA-CPRS to a number of international delegations interested in adopting this open source EHR in their countries. He supervised the deployment of VistA-CPRS at Max Healthcare network and Rajiv Gandhi Cancer Hospitals in New Delhi. Max Hospitals were designated by HIMSS as first Stage 6 implementation in India back in 2012.Dr. Tirmizi was a keynote speaker at a number of international conferences including in the European Union, Middle-East and the Asia Pacific Region. He presented the VHA developed EHR called VistA-CPRS to a number of international delegations interested in adopting this open source EHR in their countries. He supervised the deployment of VistA-CPRS at Max Healthcare network and Rajiv Gandhi Cancer Hospitals in India. Back in 2012, Max Hospitals were designated by HIMSS as Stage 6 implementation.",
      " Dr. Tirmizi promoted a culture of Patient Safety internationally as Senior VP at Quantros – a Patient Safety Organization and later as a consultant with Pascal metrics – another Patient Safety Organization.",
      " Dr. Tirmizi is particularly interested in use of Artificial Intelligence in Healthcare to enable better, safer and efficient care. Recently he participated in the US Government Summit on AI in Healthcare and reviewed the AI roll-out strategy.",
      " Dr. Tirmizi was faculty at Texas Tech University of Health Sciences, Uniformed Services University of Health Sciences and George Mason University. He developed online Health Informatics courses on EMR, Clinical Decision Support, Change Management etc.",
    ],
    logoImg: person,

    rating: 4.5,
  },

  {
    name: "mr-dinesh-samudra",
    title: "Mr. Dinesh Samudra",
    designation: "Principal Consultant -Sales & Marketing",
    description: [
      "	Dinesh Samudra truly believes in transforming the way care is delivered by digitizing healthcare solutions. Highly driven by his passion, he embarked on a journey to develop and design healthcare solutions that would and Co-founder and CEO of Multiple Healthcare IT Organisations, improve care management by providing an innovative and patient-centric SaaS-based platform for better collaboration between healthcare providers and consumers.",
      "	With 32 years of experience in Information Technology and 21 years in Healthcare Dinesh brings to the forefront, his global expertise and knowledge in Healthcare and Health-Tech. His forte lies in conceptualising and building software solutions by mapping procedures and processes of healthcare institutions. In order to maintain service consistency, Dinesh has managed many complex operational arenas and helped healthcare organisations keep pace with medical advancements.",
      " For more than 21 years, Mr. Dinesh provided healthcare solutions to large companies and governments globally. He has worked with hospital leaders to help educate the Board about the complexities, risks, the intricacies of delivering the highest quality of care using the latest technology. He has been consulting with various medical technology services, organisations and health care leaders to discover advancements that reshape and improve caregiving practices and policies. Successfully lead individual departments, functions, projects, partnerships and service lines in Healthcare businesses.",
    ],
    logoImg: person,

    rating: 4.5,
  },
  {
    name: "mr-uday-pratap",
    title: "Mr. Uday Pratap",
    designation: "Director-Implementation",
    description: [
      "As Vice President of Product Management, Uday Pratap is responsible for the product strategy, direction setting, and channel partner development for Sansys Informatics. In addition, he is responsible for the expansion of Services business in the healthcare domain.",
      "With more than a decade of experience in the software industry and worked for healthcare domains ,Uday has vast knowledge of EHR and he played a vital role in many big implementation like Rajeev Gandhi Cancer Hospital,Max Group of hospital.He also worked for Dell as a SME for CPRS and Radiology. He worked on best EMR in market like Epic and VistA.He is certified in EPIC Ambulatory.",
    ],
    logoImg: person,

    rating: 4.5,
  },
  {
    name: "dr-satbir-singh-negi",
    title: "Dr. Satbir Singh Negi",
    designation: "Consultant",
    description: [
      "	Dr. Satbir Singh Negi serves as the Consultant - Corporate Strategy on the Management Board and plays an important role is the direction and vision of the company's growth. He comes with a young experience of 12 years in healthcare domain including clinical, technical and analytics, having worked with technology giants like Oracle, Xavient etc",
      "	Having successfully implemented HMIS and EMR in India, China and USA, now he is focused on the next generation healthcare management by smart and technologically connected and integrated systems on a Global scale.",
    ],
    logoImg: person,

    rating: 4.5,
  },
]; */
const promoters = [
  {
    name: "name",
    title: "Mrs Anupma Kashyap",
    designation: "Role/ Designation",
    description: [
      "	Integrating Sansys EHR into our trauma care operations has been a transformative experience. The system's adaptability and efficiency have greatly enhanced our ability to provide critical, time-sensitive care. From streamlined patient data management to improved coordination across departments, Sansys EHR has proven to be an invaluable tool in our daily operations. The support team at Sansys Informatics ensured a flawless implementation and continues to provide exceptional service. 	",
      "	We have seen a significant boost in both our operational capabilities and patient outcomes, making Sansys Informatics an indispensable partner in our trauma care services.	",
    ],
    logoImg: anupmaKashyap,

    rating: 4.5,
  },
  {
    name: "name",
    title: "Mr Ashutosh Tripathi",
    designation: "Role/ Designation",
    description: [
      "	Implementing the Sansys EHR system across all departments at AIIMS New Delhi has been a game-changer for our institution. The comprehensive and user-friendly platform has streamlined our processes, enhanced patient record management, and improved overall care delivery. The transition was smooth, thanks to the expertise and unwavering support from the Sansys team. 	",
      "	We have observed significant improvements in efficiency, and our medical staff can now provide more focused and timely care to our patients. 	",
      "	Sansys Informatics has truly elevated our healthcare operations, and we commend their commitment to excellence and innovation.	",
    ],
    logoImg: person,

    rating: 4.5,
  },
  {
    name: "name",
    title: "Mr Uday Pratap",
    designation: "Role/ Designation",
    description: [
      "	The introduction of Sansys EHR at our institute has revolutionized our approach to pediatric care and medical education. The system’s advanced features and intuitive interface have greatly enhanced our ability to manage patient records, streamline workflows, and provide comprehensive care. The implementation was smooth, with the Sansys team providing exceptional support and training to ensure our staff and students could fully utilize the system’s capabilities.	",
      "	We’ve seen remarkable improvements in the efficiency of our clinical operations and the quality of care we provide. Sansys Informatics has been a vital partner in elevating both our healthcare delivery and educational standards.	",
    ],
    logoImg: person,

    rating: 4.5,
  },
  {
    name: "name",
    title: "Mr Ratneshwar Mishra",
    designation: "Role/ Designation",
    description: [
      "	Integrating Sansys EHR into our trauma care operations has been a transformative experience. The system's adaptability and efficiency have greatly enhanced our ability to provide critical, time-sensitive care. From streamlined patient data management to improved coordination across departments, Sansys EHR has proven to be an invaluable tool in our daily operations. The support team at Sansys Informatics ensured a flawless implementation and continues to provide exceptional service. 	",
      "	We have seen a significant boost in both our operational capabilities and patient outcomes, making Sansys Informatics an indispensable partner in our trauma care services.	",
    ],
    logoImg: person,

    rating: 4.5,
  },
  {
    name: "name",
    title: "Dr. Syed Tirmizi",
    designation: "Role/ Designation",
    description: [
      "	Implementing the Sansys EHR system across all departments at AIIMS New Delhi has been a game-changer for our institution. The comprehensive and user-friendly platform has streamlined our processes, enhanced patient record management, and improved overall care delivery. The transition was smooth, thanks to the expertise and unwavering support from the Sansys team. 	",
      "	We have observed significant improvements in efficiency, and our medical staff can now provide more focused and timely care to our patients. 	",
      "	Sansys Informatics has truly elevated our healthcare operations, and we commend their commitment to excellence and innovation.	",
    ],
    logoImg: person,

    rating: 4.5,
  },
  {
    name: "name",
    title: "Mr Surendranath Shukla",
    designation: "Role/ Designation",
    description: [
      "	The introduction of Sansys EHR at our institute has revolutionized our approach to pediatric care and medical education. The system’s advanced features and intuitive interface have greatly enhanced our ability to manage patient records, streamline workflows, and provide comprehensive care. The implementation was smooth, with the Sansys team providing exceptional support and training to ensure our staff and students could fully utilize the system’s capabilities.	",
      "	We’ve seen remarkable improvements in the efficiency of our clinical operations and the quality of care we provide. Sansys Informatics has been a vital partner in elevating both our healthcare delivery and educational standards.	",
    ],
    logoImg: person,

    rating: 4.5,
  },
  {
    name: "name",
    title: "Mr Rishi Dubey",
    designation: "Role/ Designation",
    description: [
      "	The introduction of Sansys EHR at our institute has revolutionized our approach to pediatric care and medical education. The system’s advanced features and intuitive interface have greatly enhanced our ability to manage patient records, streamline workflows, and provide comprehensive care. The implementation was smooth, with the Sansys team providing exceptional support and training to ensure our staff and students could fully utilize the system’s capabilities.	",
      "	We’ve seen remarkable improvements in the efficiency of our clinical operations and the quality of care we provide. Sansys Informatics has been a vital partner in elevating both our healthcare delivery and educational standards.	",
    ],
    logoImg: person,

    rating: 4.5,
  },
  {
    name: "name",
    title: "Mr Santosh Mishra",
    designation: "Role/ Designation",
    description: [
      "	The introduction of Sansys EHR at our institute has revolutionized our approach to pediatric care and medical education. The system’s advanced features and intuitive interface have greatly enhanced our ability to manage patient records, streamline workflows, and provide comprehensive care. The implementation was smooth, with the Sansys team providing exceptional support and training to ensure our staff and students could fully utilize the system’s capabilities.	",
      "	We’ve seen remarkable improvements in the efficiency of our clinical operations and the quality of care we provide. Sansys Informatics has been a vital partner in elevating both our healthcare delivery and educational standards.	",
    ],
    logoImg: santoshMishra,

    rating: 4.5,
  },
  {
    name: "name",
    title: "Mrs Alka Chauhan",
    designation: "Role/ Designation",
    description: [
      "	The introduction of Sansys EHR at our institute has revolutionized our approach to pediatric care and medical education. The system’s advanced features and intuitive interface have greatly enhanced our ability to manage patient records, streamline workflows, and provide comprehensive care. The implementation was smooth, with the Sansys team providing exceptional support and training to ensure our staff and students could fully utilize the system’s capabilities.	",
      "	We’ve seen remarkable improvements in the efficiency of our clinical operations and the quality of care we provide. Sansys Informatics has been a vital partner in elevating both our healthcare delivery and educational standards.	",
    ],
    logoImg: person,

    rating: 4.5,
  },
];
const advisors = [
  {
    name: "name",
    title: "Dr. Deepak Agarwal",
    designation: "Professor Neurosurgery at AIIMS, New Delhi",
    description: [
      "	The implementation of Sansys EHR across all our hospitals has truly transformed the way we deliver healthcare at Max Healthcare. The seamless integration and intuitive design of the system have significantly improved our operational efficiency, enabling our medical teams to focus more on patient care. The support from the Sansys team has been exceptional, providing us with the training and resources needed to fully leverage the capabilities of the EHR system. 	",
      "	We’ve seen a marked improvement in patient outcomes, and the feedback from our staff has been overwhelmingly positive. 	",
      "	Sansys Informatics has proven to be a reliable partner in our journey towards digital transformation, and we highly recommend their solutions to any healthcare organization looking to enhance their services.	",
    ],
    logoImg: deepakAgarwal,

    rating: 4.5,
  },

  {
    name: "name",
    title: "Mr Rob Colodner",
    designation: "Role/ Designation",
    description: [
      "	The introduction of Sansys EHR at our institute has revolutionized our approach to pediatric care and medical education. The system’s advanced features and intuitive interface have greatly enhanced our ability to manage patient records, streamline workflows, and provide comprehensive care. The implementation was smooth, with the Sansys team providing exceptional support and training to ensure our staff and students could fully utilize the system’s capabilities.	",
      "	We’ve seen remarkable improvements in the efficiency of our clinical operations and the quality of care we provide. Sansys Informatics has been a vital partner in elevating both our healthcare delivery and educational standards.	",
    ],
    logoImg: person,

    rating: 4.5,
  },
  {
    name: "name",
    title: "Mr Frank Stewart",
    designation: "Role/ Designation",
    description: [
      "	The introduction of Sansys EHR at our institute has revolutionized our approach to pediatric care and medical education. The system’s advanced features and intuitive interface have greatly enhanced our ability to manage patient records, streamline workflows, and provide comprehensive care. The implementation was smooth, with the Sansys team providing exceptional support and training to ensure our staff and students could fully utilize the system’s capabilities.	",
      "	We’ve seen remarkable improvements in the efficiency of our clinical operations and the quality of care we provide. Sansys Informatics has been a vital partner in elevating both our healthcare delivery and educational standards.	",
    ],
    logoImg: person,

    rating: 4.5,
  },
  {
    name: "name",
    title: "Dr. Sandeep Gupta",
    designation: "Role/ Designation",
    description: [
      "	The implementation of Sansys EHR across all our hospitals has truly transformed the way we deliver healthcare at Max Healthcare. The seamless integration and intuitive design of the system have significantly improved our operational efficiency, enabling our medical teams to focus more on patient care. The support from the Sansys team has been exceptional, providing us with the training and resources needed to fully leverage the capabilities of the EHR system. 	",
      "	We’ve seen a marked improvement in patient outcomes, and the feedback from our staff has been overwhelmingly positive. 	",
      "	Sansys Informatics has proven to be a reliable partner in our journey towards digital transformation, and we highly recommend their solutions to any healthcare organization looking to enhance their services.	",
    ],
    logoImg: sandeepGupta,

    rating: 4.5,
  },
  {
    name: "name",
    title: "Mr Sumit Puri",
    designation: "Role/ Designation",
    description: [
      "	The implementation of Sansys EHR across all our hospitals has truly transformed the way we deliver healthcare at Max Healthcare. The seamless integration and intuitive design of the system have significantly improved our operational efficiency, enabling our medical teams to focus more on patient care. The support from the Sansys team has been exceptional, providing us with the training and resources needed to fully leverage the capabilities of the EHR system. 	",
      "	We’ve seen a marked improvement in patient outcomes, and the feedback from our staff has been overwhelmingly positive. 	",
      "	Sansys Informatics has proven to be a reliable partner in our journey towards digital transformation, and we highly recommend their solutions to any healthcare organization looking to enhance their services.	",
    ],
    logoImg: person,

    rating: 4.5,
  },
  {
    name: "name",
    title: "Mr J P Dwivedi",
    designation: "Chief Information Officer, IT",
    description: [
      "	Jai Prakash was born in farmer's family in rural belt of Bundelkhand. He did his B.Sc. from University of Allahabad, M.C.A. from M.N.N.I.T. Allahabad and one year postgraduate program in management from The Nottingham Trent University, UK.",
      "	Starting with ITI Ltd Mankapur, he has served in Indian Railways, HCL, Perot Systems and Dell Corporation before joining RGCI. During his corporate career, he has worked in India, UK, USA, Singapore and travelled extensively to numerous western countries.",
      "	He has led several large and complex projects including Enterprise Architecture, Back-office Automation, Client Portal Development and System re-engineering. His domain experience includes healthcare, telecommunications, transport, hospitality, railways, energy, investment banking, HR and several others. He entered the world of healthcare in 2009 with overall responsibility of implementing Electronic Health Record in Max Healthcare, New Delhi. He is passionate about improving patient care by use of information technology and process automation.",
    ],
    logoImg: jpDwivedi,

    rating: 4.5,
  },
  {
    name: "name",
    title: "Dr. Alok Mullick",
    designation: "Role/ Designation",
    description: [
      "	The introduction of Sansys EHR at our institute has revolutionized our approach to pediatric care and medical education. The system’s advanced features and intuitive interface have greatly enhanced our ability to manage patient records, streamline workflows, and provide comprehensive care. The implementation was smooth, with the Sansys team providing exceptional support and training to ensure our staff and students could fully utilize the system’s capabilities.	",
      "	We’ve seen remarkable improvements in the efficiency of our clinical operations and the quality of care we provide. Sansys Informatics has been a vital partner in elevating both our healthcare delivery and educational standards.	",
    ],
    logoImg: tick,

    rating: 4.5,
  },
  {
    name: "name",
    title: "Dr. Vibha",
    designation: "Role/ Designation",
    description: [
      "	The introduction of Sansys EHR at our institute has revolutionized our approach to pediatric care and medical education. The system’s advanced features and intuitive interface have greatly enhanced our ability to manage patient records, streamline workflows, and provide comprehensive care. The implementation was smooth, with the Sansys team providing exceptional support and training to ensure our staff and students could fully utilize the system’s capabilities.	",
      "	We’ve seen remarkable improvements in the efficiency of our clinical operations and the quality of care we provide. Sansys Informatics has been a vital partner in elevating both our healthcare delivery and educational standards.	",
    ],
    logoImg: person,

    rating: 4.5,
  },

  {
    name: "name",
    title: "Dr. A K Jindal",
    designation: "Role/ Designation",
    description: [
      "	The introduction of Sansys EHR at our institute has revolutionized our approach to pediatric care and medical education. The system’s advanced features and intuitive interface have greatly enhanced our ability to manage patient records, streamline workflows, and provide comprehensive care. The implementation was smooth, with the Sansys team providing exceptional support and training to ensure our staff and students could fully utilize the system’s capabilities.	",
      "	We’ve seen remarkable improvements in the efficiency of our clinical operations and the quality of care we provide. Sansys Informatics has been a vital partner in elevating both our healthcare delivery and educational standards.	",
    ],
    logoImg: person,

    rating: 4.5,
  },
  {
    name: "name",
    title: "Daniel",
    designation: "Role/ Designation",
    description: [
      "	The introduction of Sansys EHR at our institute has revolutionized our approach to pediatric care and medical education. The system’s advanced features and intuitive interface have greatly enhanced our ability to manage patient records, streamline workflows, and provide comprehensive care. The implementation was smooth, with the Sansys team providing exceptional support and training to ensure our staff and students could fully utilize the system’s capabilities.	",
      "	We’ve seen remarkable improvements in the efficiency of our clinical operations and the quality of care we provide. Sansys Informatics has been a vital partner in elevating both our healthcare delivery and educational standards.	",
    ],
    logoImg: person,

    rating: 4.5,
  },
  {
    name: "name",
    title: "Dr. Adesh Shrivastav",
    designation: "Role/ Designation",
    description: [
      "	The introduction of Sansys EHR at our institute has revolutionized our approach to pediatric care and medical education. The system’s advanced features and intuitive interface have greatly enhanced our ability to manage patient records, streamline workflows, and provide comprehensive care. The implementation was smooth, with the Sansys team providing exceptional support and training to ensure our staff and students could fully utilize the system’s capabilities.	",
      "	We’ve seen remarkable improvements in the efficiency of our clinical operations and the quality of care we provide. Sansys Informatics has been a vital partner in elevating both our healthcare delivery and educational standards.	",
    ],
    logoImg: person,

    rating: 4.5,
  },
  {
    name: "name",
    title: "Mr John Bosco",
    designation: "Role/ Designation",
    description: [
      "	The implementation of Sansys EHR across all our hospitals has truly transformed the way we deliver healthcare at Max Healthcare. The seamless integration and intuitive design of the system have significantly improved our operational efficiency, enabling our medical teams to focus more on patient care. The support from the Sansys team has been exceptional, providing us with the training and resources needed to fully leverage the capabilities of the EHR system. 	",
      "	We’ve seen a marked improvement in patient outcomes, and the feedback from our staff has been overwhelmingly positive. 	",
      "	Sansys Informatics has proven to be a reliable partner in our journey towards digital transformation, and we highly recommend their solutions to any healthcare organization looking to enhance their services.	",
    ],
    logoImg: person,

    rating: 4.5,
  },

  {
    name: "name",
    title: "Mr Abdullah Saleem",
    designation: "Role/ Designation",
    description: [
      "	The implementation of Sansys EHR across all our hospitals has truly transformed the way we deliver healthcare at Max Healthcare. The seamless integration and intuitive design of the system have significantly improved our operational efficiency, enabling our medical teams to focus more on patient care. The support from the Sansys team has been exceptional, providing us with the training and resources needed to fully leverage the capabilities of the EHR system. 	",
      "	We’ve seen a marked improvement in patient outcomes, and the feedback from our staff has been overwhelmingly positive. 	",
      "	Sansys Informatics has proven to be a reliable partner in our journey towards digital transformation, and we highly recommend their solutions to any healthcare organization looking to enhance their services.	",
    ],
    logoImg: person,

    rating: 4.5,
  },

  {
    name: "name",
    title: "Dr. Tej",
    designation: "Role/ Designation",
    description: [
      "	The introduction of Sansys EHR at our institute has revolutionized our approach to pediatric care and medical education. The system’s advanced features and intuitive interface have greatly enhanced our ability to manage patient records, streamline workflows, and provide comprehensive care. The implementation was smooth, with the Sansys team providing exceptional support and training to ensure our staff and students could fully utilize the system’s capabilities.	",
      "	We’ve seen remarkable improvements in the efficiency of our clinical operations and the quality of care we provide. Sansys Informatics has been a vital partner in elevating both our healthcare delivery and educational standards.	",
    ],
    logoImg: person,

    rating: 4.5,
  },
  {
    name: "name",
    title: "Dr. Pankaj Pandey",
    designation: "Role/ Designation",
    description: [
      "	The introduction of Sansys EHR at our institute has revolutionized our approach to pediatric care and medical education. The system’s advanced features and intuitive interface have greatly enhanced our ability to manage patient records, streamline workflows, and provide comprehensive care. The implementation was smooth, with the Sansys team providing exceptional support and training to ensure our staff and students could fully utilize the system’s capabilities.	",
      "	We’ve seen remarkable improvements in the efficiency of our clinical operations and the quality of care we provide. Sansys Informatics has been a vital partner in elevating both our healthcare delivery and educational standards.	",
    ],
    logoImg: person,

    rating: 4.5,
  },
];

const OurTeam = () => {
  /*
  const [isVisible, setIsVisible] = useState(false);
  const handleMouseClick = () => setIsVisible(true); */
  const navigate = useNavigate();

  const handleTeamClick = (name) => {
    navigate(`/our-team/${name}`);
  };

  return (
    <div className="our-teams">
      <h2>Our Leaders</h2>
      <div className="teams-parent">
        {leaders.map((team, index) => (
          <>
            <div
              key={index}
              className="teams"
              onClick={() => handleTeamClick(team.name)}
            >
              <div className="teams-image">
                <img src={team.logoImg} alt={team.title} />
              </div>
              <div className="teams-title">{team.title}</div>
              <div className="teams-designation">{team.designation}</div>
            </div>
            {/* {
              <div className="description-parent">
                {<OurTeamCard desc={team.description} />}
              </div>
            } */}
          </>
        ))}
      </div>
      <h2>Our Advisors</h2>
      <div className="teams-parent">
        {advisors.map((team, index) => (
          <div className="teams">
            <div className="teams-image">
              <img src={team.logoImg} alt={team.title} />
            </div>
            <div className="teams-title">{team.title}</div>
            <div className="teams-designation">{team.designation}</div>
          </div>
        ))}
      </div>
      <h2>Our Promoters</h2>
      <div className="teams-parent">
        {promoters.map((team, index) => (
          <div className="teams">
            <div className="teams-image">
              <img src={team.logoImg} alt={team.title} />
            </div>
            <div className="teams-title">{team.title}</div>
            <div className="teams-designation">{team.designation}</div>
          </div>
        ))}
      </div>
    </div>
  );
};
export default OurTeam;
