// Example: Home.js
import React from "react";
import Benefits from "./benefits";
import Solutions from "./solutions";
import MapComponent from "./MapComponent";
import FullWidthCarousel from "./hero2";
const Home = () => {
  const locations = [
    {
      name: "Nanavati-MAX Super Speciality Hospital, Mumbai",
      coordinates: { lat: 19.09604459729789, lng: 72.84026099567319 },
    },
    {
      name: "MAX Super Speciality Hospital, Nagpur",
      coordinates: { lat: 21.190656701673753, lng: 79.08017947563476 },
    },
    {
      name: "MAX Super Speciality Hospital, Dehradun",
      coordinates: { lat: 30.37360490406808, lng: 78.07454131753353 },
    },
    {
      name: "MAX Super Speciality Hospital, Mohali",
      coordinates: { lat: 30.740236737912134, lng: 76.71433901140698 },
    },
    {
      name: "MAX Super Speciality Hospital, Bhatinda",
      coordinates: { lat: 30.192469269070063, lng: 74.94714631138491 },
    },
    {
      name: "MAX Super Speciality Hospital, Lucknow",
      coordinates: { lat: 26.850269264376028, lng: 81.02421335358594 },
    },
    {
      name: "MAX Super Speciality Hospital, Saket",
      coordinates: { lat: 28.679447991199332, lng: 77.1312652665928 },
    },
    {
      name: "MAX Smart Super Speciality Hospital, Saket",
      coordinates: { lat: 28.528106246956863, lng: 77.21497878248391 },
    },
    {
      name: "MAX Super Speciality Hospital, Patparganj",
      coordinates: { lat: 28.633182811690517, lng: 77.30898113830804 },
    },
    {
      name: "BLK-MAX Super Speciality Hospital",
      coordinates: { lat: 28.64351994000733, lng: 77.17969436714446 },
    },
    {
      name: "MAX Super Speciality Hospital, Vaishali",
      coordinates: { lat: 28.634807924533863, lng: 77.3330966536521 },
    },
    {
      name: "MAX Super Speciality Hospital, Shalimar Bagh",
      coordinates: { lat: 28.728123823837304, lng: 77.15295762481963 },
    },
    {
      name: "MAX Hospital, Gurugram",
      coordinates: { lat: 28.461543846844318, lng: 77.0748168978253 },
    },
    {
      name: "MAX Multi Speciality Centre, Panchsheel Park",
      coordinates: { lat: 28.54357664763223, lng: 77.21436730761693 },
    },
    {
      name: "MAX Multi Speciality Centre, Noida",
      coordinates: { lat: 28.574605690665003, lng: 77.32290948063388 },
    },
    {
      name: "MAX Institute of Cancer Care, Lajpat Nagar",
      coordinates: { lat: 28.564733156675516, lng: 77.23988548063355 },
    },
    {
      name: "MAX Super Speciality Hospital, Dwarka",
      coordinates: { lat: 28.58283860132267, lng: 77.05098838063417 },
    },

    {
      name: "AIIMS Delhi",
      coordinates: { lat: 28.567933660159337, lng: 77.2084094113214 },
    },
    {
      name: "JPN-ATC, Delhi",
      coordinates: { lat: 28.568396772369283, lng: 77.2006557094697 },
    },
    {
      name: "LHC, Lucknow",
      coordinates: { lat: 26.828181013145173, lng: 81.0102057805693 },
    },
    {
      name: "LNJP, New Delhi",
      coordinates: { lat: 28.63914863279094, lng: 77.23823131132407 },
    },
    {
      name: "RGCI, New Delhi",
      coordinates: { lat: 28.562001577607944, lng: 77.2164295959773 },
    },
    {
      name: "KIMS, Kerala",
      coordinates: { lat: 8.513998277865229, lng: 76.9093772819805 },
    },
    {
      name: "NCHRC, Nepal",
      coordinates: { lat: 27.643527034454145, lng: 85.33893319409107 },
    },
    {
      name: "ICS, New Delhi",
      coordinates: { lat: 28.565894823416755, lng: 77.2173904305465 },
    },
    {
      name: "SSPHGTI, Noida",
      coordinates: { lat: 28.577150589717156, lng: 77.33761259412611 },
    },
    {
      name: "CMC Ludhiana",
      coordinates: { lat: 30.911101044163388, lng: 75.86359165374198 },
    },
  ];
  return (
    <div>
      <FullWidthCarousel />
      <Benefits />
      <Solutions />
      <div className="map">
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
          }}
        >
          <div>
            <p className="map-head">
              Transforming Healthcare, Expanding Excellence Globally
            </p>
            <p className="map-subhead">
              <p>
                Sansys Informatics Pvt. Ltd. has revolutionized healthcare
                automation in India,implementing innovative solutions for top
                clients like Max Healthcare, AIIMS,RGCI, LJN, KIMS, LHC and many
                others. Our advanced HIS, EMR, and ERP solutions enhance
                efficiency and patient care.
              </p>
              <p>
                Our vision is global expansion, bringing our innovative
                technology and exceptional support to healthcare providers
                worldwide.
              </p>
            </p>
          </div>
          <div>
            <p style={{ fontSize: "17px" }}>
              <b>
                <i>
                  Join us in shaping the future of healthcare through our
                  Next-Gen product suite & unmatched healthcare automation
                  services..!
                </i>
              </b>
            </p>
          </div>
        </div>
        <div>
          <MapComponent locations={locations} />
        </div>
      </div>
    </div>
  );
};

export default Home;
