import React, { useEffect } from "react";
import size from "../images/icons/size.png";
import speed from "../images/icons/speed.png";
import representative from "../images/icons/india.png";
import integrated from "../images/icons/settings.png";
import diverse from "../images/icons/diversity.png";
import { motion } from "framer-motion";
import "./Benefits.css";

const Card = ({ title, subtitle, imageUrl }) => {
  return (
    <motion.div
      className="card"
      initial={{ opacity: 0, y: 20 }}
      animate={{ opacity: 1, y: 0 }}
      whileHover={{ scale: 1.05 }}
      whileTap={{ scale: 0.95 }}
      transition={{ duration: 0.3 }}
    >
      <div className="card-image">
        <img src={imageUrl} alt="" />
      </div>
      <div className="card-content">
        <h2 className="card-title">{title}</h2>
        <p className="card-subtitle">{subtitle}</p>
      </div>
    </motion.div>
  );
};

const Benefits = () => {
  const sliders = [
    {
      title: "Operational Efficiency and Integration",
      subtitle:
        "Optimize workflows with our comprehensive suite of EHR, HIS, and ancillary systems, seamlessly integrating with existing standards and ensuring quick, efficient deployment with minimal downtime.",
      bgImg: size,
    },
    {
      title: "Cost-Effectiveness and Scalability",
      subtitle: "Cost-Effective solutions, adaptable to various healthcare settings and scalable to accommodate growth and evolving needs.",
      bgImg: speed,
    },
    {
      title: "Robust Security and Comprehensive Compliance",
      subtitle:
        "Ensure secure and confidential handling of health information, maintaining full alignment with industry standards and through stringent data protection and thorough compliance measures.",
      bgImg: representative,
    },
    {
      title: "Sophisticated Technology and Ongoing Innovation",
      subtitle:
        "Utilize the latest, most efficient technology for a streamlined and intuitive user experience while implementing groundbreaking updates to stay ahead of industry trends.",
      bgImg: integrated,
    },
    {
      title: "Dedicated Support ensuring Enhanced Patient Care",
      subtitle:
        "Provide reliable, round-the-clock support to ensure uninterrupted operations while delivering exceptional, patient-centric care through timely information and advanced support tools.",
      bgImg: diverse,
    },
  ];

  useEffect(() => {
    console.log("Benefits Mounted");
  }, []);

  return (
    <>
      <motion.div
        className="sol-head"
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
        transition={{ duration: 0.5 }}
      >
        <div className="h-line"></div>
        <h1>Key Benefits</h1>
        <div className="h-line"></div>
      </motion.div>
      <motion.div
        className="benefits"
        initial={{ y: "100%", opacity: 0 }}
        animate={{ opacity: 1, y: "0px" }}
        exit={{ opacity: 0, visibility: "hidden" }}
        transition={{ duration: 0.2 }}
      >
        {sliders.map((slider, index) => (
          <Card
            key={index}
            title={slider.title}
            subtitle={slider.subtitle}
            imageUrl={slider.bgImg}
          />
        ))}
      </motion.div>
    </>
  );
};

export default Benefits;


/* import React, { useEffect } from "react";
import size from "../images/icons/size.png";
import speed from "../images/icons/speed.png";
import representative from "../images/icons/india.png";
import integrated from "../images/icons/settings.png";
import diverse from "../images/icons/diversity.png";

import { motion } from "framer-motion";

import "./Benefits.css";

const Card = ({ title, subtitle, imageUrl }) => {
  return (
    <div className="card">
      <div className="card-image">
        <img src={imageUrl} alt="" />
      </div>
      <div className="card-content">
        <h2 className="card-title">{title}</h2>
        <p className="card-subtitle">{subtitle}</p>
      </div>
    </div>
  );
};

const Benefits = () => {
  const sliders = [
    {
      title: "Operational Efficiency and Integration",
      subtitle:
        "Optimize workflows with our comprehensive suite of EHR, HIS, and ancillary systems, seamlessly integrating with existing standards and ensuring quick, efficient deployment with minimal downtime.",
      bgImg: size,
    },
    {
      title: "Cost-Effectiveness and Scalability",
      subtitle: "Cost-Effective solutions, adaptable to various healthcare settings and scalable to accommodate growth and evolving needs.",
      bgImg: speed,
    },
    {
      title: "Robust Security and Comprehensive Compliance",
      subtitle:
        "Ensure secure and confidential handling of health information, maintaining full alignment with industry standards and regulatory requirements through stringent data protection and thorough compliance measures.",
      bgImg: representative,
    },
    {
      title: "Sophisticated Technology and Ongoing Innovation",
      subtitle:
        "Utilize the latest, most efficient technology for a streamlined and intuitive user experience while implementing groundbreaking updates to stay ahead of industry trends.",
      bgImg: integrated,
    },
    {
      title: "Dedicated Support ensuring Enhanced Patient Care",
      subtitle:
        "Provide reliable, round-the-clock support to ensure uninterrupted operations while delivering exceptional, patient-centric care through timely information and advanced support tools.",
      bgImg: diverse,
    },
  ];
  useEffect(() => {
    console.log("Benefits Mounted");
  }, []);
  return (
    <>
      <motion.div
        className="sol-head"
        initial={{ opacity: 0 }}
        whileInView={{ opacity: 1 }}
        exit={{ opacity: 0 }}
        transition={{ duration: 0.5 }}
      >
        <div className="h-line"></div>
        <h1>Key Benefits</h1>
        <div className="h-line"></div>
      </motion.div>
      <motion.div
        className="benefits"
        initial={{ y: "100%", opacity: 0 }}
        whileInView={{ opacity: 1, y: "40px" }}
        exit={{ opacity: 0, visibility: "hidden" }}
        transition={{ duration: 0.2 }}
      >
        {sliders.map((slider, index) => (
          <Card
            key={index}
            title={slider.title}
            subtitle={slider.subtitle}
            imageUrl={slider.bgImg}
          />
        ))}
      </motion.div>
    </>
  );
};

export default Benefits;
 */