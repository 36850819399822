/* import TileVM from "./TileVM"; */
import visImg from "../images/vision.jpg";
import "./OurVision.css";
const visions = [
  {
    title: "Transforming Patient Care",
    description: [
      "We are committed to enhancing patient care by delivering advanced, reliable, and user-friendly EMR systems. Our solutions are designed to improve clinical outcomes, streamline workflows, and ensure that healthcare providers have access to accurate, real-time patient data at every stage of care.",
    ],
    image: visImg,
  },
  {
    title: "Reducing Operational Costs",
    description: [
      "In today’s competitive healthcare environment, operational efficiency is key. Our Sansys EHR solutions are engineered to decrease the operational costs of hospitals by optimizing resource allocation, reducing errors, and automating administrative tasks. This not only frees up valuable time for healthcare professionals but also translates into substantial cost savings for the organization.",
    ],
    image: visImg,
  },
  {
    title: "Increasing Hospital Revenue",
    description: [
      "We understand that financial sustainability is crucial for healthcare institutions. Our EMR systems are tailored to increase hospital revenue by improving billing accuracy, reducing claim denials, and enhancing overall financial management. By providing a comprehensive view of hospital operations, we empower organizations to make informed decisions that drive financial growth.",
    ],
    image: visImg,
  },
  {
    title: "Affordability Without Compromise",
    description: [
      "Quality healthcare should be accessible to all, regardless of budget constraints. We are dedicated to offering our world-class Sansys EHR solutions at an affordable cost, ensuring that even hospitals with limited financial resources can achieve a fully paperless, highly efficient system. Our rapid implementation plans are designed to minimize disruption and maximize value, enabling hospitals to transition smoothly and swiftly.",
    ],
    image: visImg,
  },
  {
    title: "Achieving Global Standards of Excellence",
    description: [
      "Our ultimate goal is to help healthcare organizations around the world reach new heights of excellence. By implementing our Sansys EHR under a rapid and effective deployment strategy, we ensure that our clients can meet and exceed global healthcare standards. We strive to be a trusted partner in your journey towards a more efficient, patient-centered, and financially sustainable future.",
    ],
    image: visImg,
  },
];
const OurVision = () => {
  return (
    <div className="vision-page">
      <div className="vision-intro">
        Compassion with Patient Care through our SANSYS EHR / SANSYS HIMS
      </div>
      <div className="vision-checks">
        <div className="vision-content">
          <h5 style={{ textAlign: "left" }}>
            At Sansys Informatics Pvt. Ltd., our vision is clear and ambitious:
            To revolutionize healthcare through the implementation of
            meaningful, efficient, and cost-effective Sanays EHR, that set the
            standard for excellence in healthcare management globally.
          </h5>
          {visions.map(
            (vision, index) => (
              <div className="vision">
                <h5>{vision.title}</h5>
                <ul>
                  <li>
                    <p>{vision.description}</p>
                  </li>
                </ul>
              </div>
            ) /* (
        <TileVM
          key={index}
          title={vision.title}
          description={vision.description}
          image={vision.image}
          index={index}
        />
      ) */
          )}
          {/* <h5 style={{ textAlign: "left" }}>
            The use of technology influences how nurses do their work and
            interact with patients. As advances in digital health continue to
            evolve, future research should aim to expand understanding of
            compassion relevant to digital health by articulating its
            characteristics and associated competencies for nurses to further
            enhance their ability to provide compassionate care when digital
            health technologies and services are used to support care delivery.
          </h5> */}
        </div>
        <div className="bg-overlay"></div>
        <div
          className="vision-image"
          style={{
            backgroundImage: `url(${visImg})`,
            backgroundRepeat: "no-repeat",
            backgroundSize: "cover",
            backgroundPosition: "center",
          }}
        ></div>
      </div>
      <h5 style={{ textAlign: "center" }}>
        Join us in transforming healthcare. Together, we can create a world
        where quality, efficiency, and accessibility are not just goals but
        realities for every healthcare organization.
      </h5>
    </div>
  );
};

export default OurVision;
