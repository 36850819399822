import React, { useState, useContext } from "react";
import { Link } from "react-router-dom";
import mac from "../images/logoImg.png";
import { Navbar, Offcanvas, Nav, Container } from "react-bootstrap";
import Product from "./Product";
import Service from "./Service";
import { motion } from "framer-motion";
import "./Navbar.css";
import AboutUs from "./AboutUs";
import { useMediaQuery } from "react-responsive"; // Import useMediaQuery hook
import { ShowContext } from "./ShowContext";

const CustomNavbar = () => {
  /* const [show, setShow] = useState(false); */
  const {
    show,
    setShow,
    isProductVisible,
    setIsProductVisible,
    isServiceVisible,
    setIsServiceVisible,
    isAboutUsVisible,
    setIsAboutUsVisible,
  } = useContext(ShowContext);
  /* const [isProductVisible, setIsProductVisible] = useState(false); */
  const [isProductDropdownVisible, setIsProductDropdownVisible] =
    useState(false);

  /* const [isServiceVisible, setIsServiceVisible] = useState(false); */
  const [isServiceDropdownVisible, setIsServiceDropdownVisible] =
    useState(false);

  /* const [isAboutUsVisible, setIsAboutUsVisible] = useState(false); */
  /* const [isAboutUsDropdownVisible, setIsAboutUsDropdownVisible] =
    useState(false); */

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const handleProductMouseEnter = () => setIsProductVisible(true);
  const handleProductMouseLeave = () => setIsProductVisible(false);
  const handleProductDropdown = () =>
    setIsProductDropdownVisible(!isProductDropdownVisible);

  const handleServiceMouseEnter = () => setIsServiceVisible(true);
  const handleServiceMouseLeave = () => setIsServiceVisible(false);
  const handleServiceDropdown = () =>
    setIsServiceDropdownVisible(!isServiceDropdownVisible);

  const handleAboutUsMouseEnter = () => setIsAboutUsVisible(true);
  const handleAboutUsMouseLeave = () => setIsAboutUsVisible(false);
  /*   const handleAboutUsDropdown = () =>
    setIsAboutUsDropdownVisible(!isAboutUsDropdownVisible); */

  // Define media queries
  const isMobile = useMediaQuery({ query: "(max-width: 767px)" });

  return (
    <>
      <Navbar expand="lg" className="navbar">
        <Container fluid>
          <Navbar.Brand href="#" className="logo">
            <img src={mac} alt="Monitor Frame" className="monitor-frame" />
            <div>
              <div className="logo-head">SANSYS INFORMATICS</div>
              <div className="logo-subhead">Innovations Destination !</div>
            </div>
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="offcanvasNavbar" onClick={handleShow} />
          <Navbar.Offcanvas
            id="offcanvasNavbar"
            aria-labelledby="offcanvasNavbarLabel"
            placement="end"
            show={show}
            onHide={handleClose}
          >
            <Offcanvas.Header closeButton>
              <Offcanvas.Title id="offcanvasNavbarLabel">Menu</Offcanvas.Title>
            </Offcanvas.Header>
            <Offcanvas.Body>
              <Nav className="justify-content-end flex-grow-1 pe-3">
                <Link to="/" className="tab" onClick={handleClose}>
                  Home
                </Link>
                <div
                  className="product-link"
                  onMouseEnter={handleProductMouseEnter}
                  onMouseLeave={handleProductMouseLeave}
                >
                  <Link
                    className="tab"
                    to={isMobile ? "#" : "/product"}
                    onClick={
                      /* isMobile ? setIsProductDropdownVisible(true) :  */ /* handleClose */
                      handleProductDropdown
                    }
                  >
                    Products
                  </Link>
                  {isMobile && isProductDropdownVisible && (
                    <Product vis={handleClose} />
                  )}
                  {isProductVisible && (
                    <motion.div
                      initial={{ y: "-120%", opacity: 0 }}
                      animate={{ opacity: 1, y: "0px" }}
                      exit={{ opacity: 0, visibility: "hidden" }}
                      transition={{ duration: 0.4 }}
                      className="product-dropdown"
                    >
                      <Product />
                    </motion.div>
                  )}
                </div>
                <div
                  className="product-link"
                  onMouseEnter={handleServiceMouseEnter}
                  onMouseLeave={handleServiceMouseLeave}
                >
                  <Link
                    to={isMobile ? "#" : "/service"}
                    className="tab"
                    onClick={handleServiceDropdown}
                  >
                    Services
                  </Link>
                  {isMobile && isServiceDropdownVisible && (
                    <Service vis={handleClose} />
                  )}
                  {isServiceVisible && (
                    <motion.div
                      initial={{ y: "-120%", opacity: 0 }}
                      animate={{ opacity: 1, y: "0px" }}
                      exit={{ opacity: 0, visibility: "hidden" }}
                      transition={{ duration: 0.4 }}
                      className="product-dropdown"
                    >
                      <Service />
                    </motion.div>
                  )}
                </div>
                <Link to="/clients" className="tab" onClick={handleClose}>
                  Our Clients
                </Link>
                <div
                  className="product-link"
                  onMouseEnter={handleAboutUsMouseEnter}
                  onMouseLeave={handleAboutUsMouseLeave}
                >
                  <Link
                    to={isMobile ? "/aboutmob" : "#"}
                    className="tab"
                    onClick={handleClose}
                  >
                    About Us
                  </Link>
                  {/* {isMobile && isAboutUsDropdownVisible && <AboutUs vis={handleClose}/>} */}
                  {isAboutUsVisible && (
                    <motion.div
                      initial={{ y: "-120%", opacity: 0 }}
                      animate={{ opacity: 1, y: "0px" }}
                      exit={{ opacity: 0, visibility: "hidden" }}
                      transition={{ duration: 0.2 }}
                      className="product-dropdown"
                    >
                      <AboutUs />
                    </motion.div>
                  )}
                </div>
                <Link to="/accolades" className="tab" onClick={handleClose}>
                  Accolades
                </Link>
                <Link to="/book-demo" className="tab-btn" onClick={handleClose}>
                  Book a Demo
                </Link>
              </Nav>
            </Offcanvas.Body>
          </Navbar.Offcanvas>
        </Container>
      </Navbar>
    </>
  );
};

export default CustomNavbar;
