import React, { useEffect, useContext } from "react";
import { Link } from "react-router-dom";
import size from "../images/icons/size.png";
/* import speed from "../images/icons/speed.png"; */
import representative from "../images/icons/india.png";
import integrated from "../images/icons/settings.png";
import diverse from "../images/icons/diversity.png";
import { motion } from "framer-motion";
import "./Product.css";
import { ShowContext } from "./ShowContext";

const ServiceCard = ({ title, subtitle, imageUrl, link }) => {
  const { setShow, setIsAboutUsVisible } = useContext(ShowContext);
  const handleCardClose = () => {
    setIsAboutUsVisible(false);
    setShow(false);
  };
  return (
    <Link to={link} className="links">
      <motion.div
        className="product-card"
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        whileHover={{ scale: 1.05 }}
        whileTap={{ scale: 0.95 }}
        transition={{ duration: 0.3 }}
        onClick={handleCardClose}
      >
        {/* <div className="product-card-image">
        <img src={imageUrl} alt="" />
      </div> */}
        <div className="product-card-content">
          <h2 className="product-card-title">{title}</h2>
          <p className="product-card-subtitle">{subtitle}</p>
        </div>
      </motion.div>
    </Link>
  );
};

const AboutUs = () => {
  const services = [
    {
      title: "Our Vision",
      subtitle: "Know our Vision.",
      link: "/our-vision",
      bgImg: size,
    },
    /* {
      title: "Our Team",
      subtitle: "Meet our Guiding Force.",
      link: "/our-team",
      bgImg: speed,
    }, */
    {
      title: "Work with Us",
      subtitle: "Discover new opportunities.",
      link: "/work-with-us",
      bgImg: representative,
    },
    {
      title: "Events",
      subtitle: "Know what's happening.",
      link: "/events",
      bgImg: integrated,
    },

    {
      title: "Brochures",
      subtitle: "Get a read !",
      link: "/brochures",
      bgImg: diverse,
    },
  ];

  useEffect(() => {
    console.log("Benefits Mounted");
  }, []);

  return (
    <>
      {/* <motion.div
            className="sol-head"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            transition={{ duration: 0.5 }}
          >
            <div className="h-line"></div>
            <h1>Key Benefits</h1>
            <div className="h-line"></div>
          </motion.div> */}
      <motion.div
        className="productsPage"
        initial={{ y: "-120%", opacity: 0 }}
        animate={{ opacity: 1, y: "0px" }}
        exit={{ opacity: 0, visibility: "hidden" }}
        transition={{ duration: 0.4 }}
      >
        {services.map((slider, index) => (
          <ServiceCard
            key={index}
            title={slider.title}
            subtitle={slider.subtitle}
            imageUrl={slider.bgImg}
            link={slider.link}
          />
        ))}
      </motion.div>
    </>
  );
};
export default AboutUs;
