import { useState } from "react";
import Rating from "./Rating";
import "./TileClient.css";
import { Carousel } from "react-bootstrap";
import maxbhatinda from "../images/clients/max-bhatinda.jpg";
import maxdehradun from "../images/clients/max-dehradun.png";
import maxdelhi from "../images/clients/max-delhi.png";
import maxgurugram from "../images/clients/max-gurugram.jpg";
import maxlajpatnagar from "../images/clients/max-lajpatnagar.png";
import maxlucknow from "../images/clients/max-lucknow.png";
import maxmohali from "../images/clients/max-mohali.jpg";
import maxnagpur from "../images/clients/max-nagpur.jpeg";
import maxnoida from "../images/clients/max-noida.png";
import maxpanchsheel from "../images/clients/max-panchsheel.jpg";
import maxpatparganj from "../images/clients/max-patparganj.jpg";
import maxsaket from "../images/clients/max-saket.png";
import maxsmart from "../images/clients/max-smart.jpg";
import maxvsh from "../images/clients/max-vsh.jpg";
import maxLogo from "../images/clients/maxLogo.png";
const TileClient = ({
  title,
  descriptions,
  rating,
  images,
  logoImg,
  index,
  /* setMaxSubs, */
}) => {
  const [showSubs, setShowSubs] = useState(true);
  const handleMore = () => {
    /* setMaxSubs(showSubs); */
    setShowSubs(!showSubs);
  };
  const maxSubsidiaries = [
    {
      title: "MAX-SAKET",
      description: [
        "	The implementation of Sansys EHR across all our hospitals has truly transformed the way we deliver healthcare at Max Healthcare. The seamless integration and intuitive design of the system have significantly improved our operational efficiency, enabling our medical teams to focus more on patient care. The support from the Sansys team has been exceptional, providing us with the training and resources needed to fully leverage the capabilities of the EHR system. 	",
        "	We’ve seen a marked improvement in patient outcomes, and the feedback from our staff has been overwhelmingly positive. 	",
        "	Sansys Informatics has proven to be a reliable partner in our journey towards digital transformation, and we highly recommend their solutions to any healthcare organization looking to enhance their services.	",
      ],
      logoImg: maxLogo,
      isSub: true,
      images: [maxsaket], // maxLogo
      rating: 4.5,
    },
    {
      title: "MAX-SMART",
      description: [
        "	The implementation of Sansys EHR across all our hospitals has truly transformed the way we deliver healthcare at Max Healthcare. The seamless integration and intuitive design of the system have significantly improved our operational efficiency, enabling our medical teams to focus more on patient care. The support from the Sansys team has been exceptional, providing us with the training and resources needed to fully leverage the capabilities of the EHR system. 	",
        "	We’ve seen a marked improvement in patient outcomes, and the feedback from our staff has been overwhelmingly positive. 	",
        "	Sansys Informatics has proven to be a reliable partner in our journey towards digital transformation, and we highly recommend their solutions to any healthcare organization looking to enhance their services.	",
      ],
      logoImg: maxLogo,
      isSub: true,
      images: [maxsmart], // maxLogo
      rating: 4.5,
    },
    {
      title: "MAX-VSH",
      description: [
        "	The implementation of Sansys EHR across all our hospitals has truly transformed the way we deliver healthcare at Max Healthcare. The seamless integration and intuitive design of the system have significantly improved our operational efficiency, enabling our medical teams to focus more on patient care. The support from the Sansys team has been exceptional, providing us with the training and resources needed to fully leverage the capabilities of the EHR system. 	",
        "	We’ve seen a marked improvement in patient outcomes, and the feedback from our staff has been overwhelmingly positive. 	",
        "	Sansys Informatics has proven to be a reliable partner in our journey towards digital transformation, and we highly recommend their solutions to any healthcare organization looking to enhance their services.	",
      ],
      logoImg: maxLogo,
      isSub: true,
      images: [maxvsh], // maxLogo
      rating: 4.5,
    },
    {
      title: "MAX-Bhatinda",
      description: [
        "	The implementation of Sansys EHR across all our hospitals has truly transformed the way we deliver healthcare at Max Healthcare. The seamless integration and intuitive design of the system have significantly improved our operational efficiency, enabling our medical teams to focus more on patient care. The support from the Sansys team has been exceptional, providing us with the training and resources needed to fully leverage the capabilities of the EHR system. 	",
        "	We’ve seen a marked improvement in patient outcomes, and the feedback from our staff has been overwhelmingly positive. 	",
        "	Sansys Informatics has proven to be a reliable partner in our journey towards digital transformation, and we highly recommend their solutions to any healthcare organization looking to enhance their services.	",
      ],
      logoImg: maxLogo,
      isSub: true,
      images: [maxbhatinda], // maxLogo
      rating: 4.5,
    },
    {
      title: "MAX-Dehradun",
      description: [
        "	The implementation of Sansys EHR across all our hospitals has truly transformed the way we deliver healthcare at Max Healthcare. The seamless integration and intuitive design of the system have significantly improved our operational efficiency, enabling our medical teams to focus more on patient care. The support from the Sansys team has been exceptional, providing us with the training and resources needed to fully leverage the capabilities of the EHR system. 	",
        "	We’ve seen a marked improvement in patient outcomes, and the feedback from our staff has been overwhelmingly positive. 	",
        "	Sansys Informatics has proven to be a reliable partner in our journey towards digital transformation, and we highly recommend their solutions to any healthcare organization looking to enhance their services.	",
      ],
      logoImg: maxLogo,
      isSub: true,
      images: [maxdehradun], // maxLogo
      rating: 4.5,
    },
    {
      title: "MAX-Delhi",
      description: [
        "	The implementation of Sansys EHR across all our hospitals has truly transformed the way we deliver healthcare at Max Healthcare. The seamless integration and intuitive design of the system have significantly improved our operational efficiency, enabling our medical teams to focus more on patient care. The support from the Sansys team has been exceptional, providing us with the training and resources needed to fully leverage the capabilities of the EHR system. 	",
        "	We’ve seen a marked improvement in patient outcomes, and the feedback from our staff has been overwhelmingly positive. 	",
        "	Sansys Informatics has proven to be a reliable partner in our journey towards digital transformation, and we highly recommend their solutions to any healthcare organization looking to enhance their services.	",
      ],
      logoImg: maxLogo,
      isSub: true,
      images: [maxdelhi], // maxLogo
      rating: 4.5,
    },
    {
      title: "MAX-Gurugram",
      description: [
        "	The implementation of Sansys EHR across all our hospitals has truly transformed the way we deliver healthcare at Max Healthcare. The seamless integration and intuitive design of the system have significantly improved our operational efficiency, enabling our medical teams to focus more on patient care. The support from the Sansys team has been exceptional, providing us with the training and resources needed to fully leverage the capabilities of the EHR system. 	",
        "	We’ve seen a marked improvement in patient outcomes, and the feedback from our staff has been overwhelmingly positive. 	",
        "	Sansys Informatics has proven to be a reliable partner in our journey towards digital transformation, and we highly recommend their solutions to any healthcare organization looking to enhance their services.	",
      ],
      logoImg: maxLogo,
      isSub: true,
      images: [maxgurugram], // maxLogo
      rating: 4.5,
    },
    {
      title: "MAX-Lajpatnagar",
      description: [
        "	The implementation of Sansys EHR across all our hospitals has truly transformed the way we deliver healthcare at Max Healthcare. The seamless integration and intuitive design of the system have significantly improved our operational efficiency, enabling our medical teams to focus more on patient care. The support from the Sansys team has been exceptional, providing us with the training and resources needed to fully leverage the capabilities of the EHR system. 	",
        "	We’ve seen a marked improvement in patient outcomes, and the feedback from our staff has been overwhelmingly positive. 	",
        "	Sansys Informatics has proven to be a reliable partner in our journey towards digital transformation, and we highly recommend their solutions to any healthcare organization looking to enhance their services.	",
      ],
      logoImg: maxLogo,
      isSub: true,
      images: [maxlajpatnagar], // maxLogo
      rating: 4.5,
    },
    {
      title: "MAX-Lucknow",
      description: [
        "	The implementation of Sansys EHR across all our hospitals has truly transformed the way we deliver healthcare at Max Healthcare. The seamless integration and intuitive design of the system have significantly improved our operational efficiency, enabling our medical teams to focus more on patient care. The support from the Sansys team has been exceptional, providing us with the training and resources needed to fully leverage the capabilities of the EHR system. 	",
        "	We’ve seen a marked improvement in patient outcomes, and the feedback from our staff has been overwhelmingly positive. 	",
        "	Sansys Informatics has proven to be a reliable partner in our journey towards digital transformation, and we highly recommend their solutions to any healthcare organization looking to enhance their services.	",
      ],
      logoImg: maxLogo,
      isSub: true,
      images: [maxlucknow], // maxLogo
      rating: 4.5,
    },
    {
      title: "MAX-Mohali",
      description: [
        "	The implementation of Sansys EHR across all our hospitals has truly transformed the way we deliver healthcare at Max Healthcare. The seamless integration and intuitive design of the system have significantly improved our operational efficiency, enabling our medical teams to focus more on patient care. The support from the Sansys team has been exceptional, providing us with the training and resources needed to fully leverage the capabilities of the EHR system. 	",
        "	We’ve seen a marked improvement in patient outcomes, and the feedback from our staff has been overwhelmingly positive. 	",
        "	Sansys Informatics has proven to be a reliable partner in our journey towards digital transformation, and we highly recommend their solutions to any healthcare organization looking to enhance their services.	",
      ],
      logoImg: maxLogo,
      isSub: true,
      images: [maxmohali], // maxLogo
      rating: 4.5,
    },
    {
      title: "MAX-Nagpur",
      description: [
        "	The implementation of Sansys EHR across all our hospitals has truly transformed the way we deliver healthcare at Max Healthcare. The seamless integration and intuitive design of the system have significantly improved our operational efficiency, enabling our medical teams to focus more on patient care. The support from the Sansys team has been exceptional, providing us with the training and resources needed to fully leverage the capabilities of the EHR system. 	",
        "	We’ve seen a marked improvement in patient outcomes, and the feedback from our staff has been overwhelmingly positive. 	",
        "	Sansys Informatics has proven to be a reliable partner in our journey towards digital transformation, and we highly recommend their solutions to any healthcare organization looking to enhance their services.	",
      ],
      logoImg: maxLogo,
      isSub: true,
      images: [maxnagpur], // maxLogo
      rating: 4.5,
    },
    {
      title: "MAX-Noida",
      description: [
        "	The implementation of Sansys EHR across all our hospitals has truly transformed the way we deliver healthcare at Max Healthcare. The seamless integration and intuitive design of the system have significantly improved our operational efficiency, enabling our medical teams to focus more on patient care. The support from the Sansys team has been exceptional, providing us with the training and resources needed to fully leverage the capabilities of the EHR system. 	",
        "	We’ve seen a marked improvement in patient outcomes, and the feedback from our staff has been overwhelmingly positive. 	",
        "	Sansys Informatics has proven to be a reliable partner in our journey towards digital transformation, and we highly recommend their solutions to any healthcare organization looking to enhance their services.	",
      ],
      logoImg: maxLogo,
      isSub: true,
      images: [maxnoida], // maxLogo
      rating: 4.5,
    },
    {
      title: "MAX-Panchsheel",
      description: [
        "	The implementation of Sansys EHR across all our hospitals has truly transformed the way we deliver healthcare at Max Healthcare. The seamless integration and intuitive design of the system have significantly improved our operational efficiency, enabling our medical teams to focus more on patient care. The support from the Sansys team has been exceptional, providing us with the training and resources needed to fully leverage the capabilities of the EHR system. 	",
        "	We’ve seen a marked improvement in patient outcomes, and the feedback from our staff has been overwhelmingly positive. 	",
        "	Sansys Informatics has proven to be a reliable partner in our journey towards digital transformation, and we highly recommend their solutions to any healthcare organization looking to enhance their services.	",
      ],
      logoImg: maxLogo,
      isSub: true,
      images: [maxpanchsheel], // maxLogo
      rating: 4.5,
    },
    {
      title: "MAX-Patparganj",
      description: [
        "	The implementation of Sansys EHR across all our hospitals has truly transformed the way we deliver healthcare at Max Healthcare. The seamless integration and intuitive design of the system have significantly improved our operational efficiency, enabling our medical teams to focus more on patient care. The support from the Sansys team has been exceptional, providing us with the training and resources needed to fully leverage the capabilities of the EHR system. 	",
        "	We’ve seen a marked improvement in patient outcomes, and the feedback from our staff has been overwhelmingly positive. 	",
        "	Sansys Informatics has proven to be a reliable partner in our journey towards digital transformation, and we highly recommend their solutions to any healthcare organization looking to enhance their services.	",
      ],
      logoImg: maxLogo,
      isSub: true,
      images: [maxpatparganj], // maxLogo
      rating: 4.5,
    },
  ];
  return (
    <div className="client-tile-parent">
      <div className="client-tile">
        <div className="client-overlay"></div>
        <div className="client-logo">
          <Carousel
            controls={false}
            indicators={false}
            interval={3000}
            pause={"hover"}
            fade
            className="custom-carousel-clients"
          >
            {images.map((image, index) => (
              <Carousel.Item
                key={index}
                className="carousel-item-clients"
                style={{
                  position: "relative",
                  width: "100%",
                  overflow: "hidden",
                }}
              >
                <div
                  className="carousel-bg-clients"
                  style={{
                    backgroundImage: `url(${image})`,
                    backgroundSize: "cover",
                    backgroundRepeat: "no-repeat",
                    backgroundPosition: "center",
                    height: "100%",
                    width: "100%",
                  }}
                ></div>
              </Carousel.Item>
            ))}
          </Carousel>
          <div className="cl-logo-f">
            <div
              className="cl-logo"
              style={{
                backgroundImage: `url(${logoImg})`,
                backgroundSize: "contain",
                backgroundRepeat: "no-repeat",
                backgroundPosition: "center",
              }}
            >
              {/* <img src={logoImg} alt={title} /> */}
            </div>
          </div>
        </div>
        <div className="client-content">
          <h3 className="client-title">{title}</h3>
          <div className="client-subtitle">
            {descriptions.map((description, i) => (
              <p key={i} dangerouslySetInnerHTML={{ __html: description }}></p>
            ))}
          </div>
          <div>
            <Rating rating={rating} />
          </div>
          {index === 0 && (
            <div className="read-more" onClick={handleMore}>
              {!showSubs ? "Collapse PAN-MAX" : "Click to view PAN-MAX"}{" "}
              &nbsp;&nbsp;&rarr;
              {/* <Rating rating={rating} /> */}
            </div>
          )}
        </div>
      </div>
      <div className="max-subs-parent">
        {!showSubs &&
          maxSubsidiaries.map((maxs, index) => (
            <div className="max-subs">
              <div className="max-subs-image">
                <img src={maxs.images} alt={maxs.title} />
              </div>
              <div className="max-subs-title">{maxs.title}</div>
            </div>
          ))}
      </div>
    </div>
  );
};

export default TileClient;

/* import React from "react";
import Rating from "./Rating";
import Slider from "react-slick";
import "./TileClient.css";
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";
import { Carousel } from "react-bootstrap";

const TileClient = ({ title, descriptions, rating, images }) => {
  const settings = {
    //dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    arrows: false, 
  };

  return (
    <div className="client-tile">
      <div className="client-overlay"></div>
      <div className="client-logo">
        <Slider {...settings}>
          {images.map((image, index) => (
            <div key={index}>
              <img src={image} alt={`${title} ${index + 1}`} />
            </div>
          ))}
        </Slider>
      </div>
      <div className="client-content">
        <h3 className="client-title">{title}</h3>
        <div className="client-subtitle">
          {descriptions.map((description, i) => (
            <p key={i}>{description}</p>
          ))}
        </div>
        <div>
          <Rating rating={rating} />
        </div>
      </div>
    </div>
  );
};

export default TileClient; */
/* 

import Rating from "./Rating";
import "./TileClient.css";

import { Carousel } from "react-bootstrap";
const TileClient = ({ title, descriptions, rating, images }) => {
  return (
    <div className="client-tile">
      <div className="client-overlay"></div>
      <div className="client-logo">
      <Carousel
        controls={true}
        indicators={true}
        interval={3000}
        pause={"hover"} 
        fade
        className="custom-carousel"
      >
        {images.map((image, index) => (
          <Carousel.Item
            key={index}
            className={index === 2 ? "stretch-bg" : ""}
            style={{
              position: "relative",
              width: "100%",
              overflow: "hidden",
            }}
          >
            <div
              className="carousel-bg"
              key={index}
              style={{
                backgroundImage: {image},
              }}
            >
            </div>
          </Carousel.Item>
        ))}
      </Carousel>
        {<img src={image} alt={title} />}
      </div>
      <div className="client-content">
        <h3 className="client-title">{title}</h3>
        <div className="client-subtitle">
          {descriptions.map((description, i) => (
            <p key={i}>{description}</p>
          ))}
        </div>
        <div>
          <Rating rating={rating} />
        </div>
      </div>
    </div>
  );
};

export default TileClient;
 */
