import React, { useState } from "react";
import "./OrganizationHierarchy.css";

function findEmployeeById(empId, employees) {
  return employees.find((emp) => emp.id === empId);
}

function Employee({ empId, employees, level = 1 }) {
  const [isOpen, setIsOpen] = useState(true);
  const employee = findEmployeeById(empId, employees);

  if (!employee) return null;

  const isCard2 = level >= 3; // From 3rd level onwards
  const isLvl2 = level === 2;
  return (
    <div className={`employee ${isCard2 ? "card2" : ""}`}>
      <div
        className={`employee-card ${isCard2 ? "card2-layout" : ""}`}
        onClick={() => setIsOpen(!isOpen)}
      >
        {isLvl2 ? <></> : <img src={employee.imageUrl} alt={employee.name} />}
        <div className="employee-info" style={{ display: "flex" }}>
          {isLvl2 ? (
            <>
              <h3>
                <b>{employee.name}</b>
              </h3>
            </>
          ) : (
            <>
              <h3>{employee.name}</h3>
              <p>{employee.designation}</p>
            </>
          )}
        </div>
      </div>
      {isOpen && employee.subordinates.length > 0 && (
        <div className="subordinates">
          {employee.subordinates.map((subId) => (
            <Employee
              key={subId}
              empId={subId}
              employees={employees}
              level={level + 1}
            />
          ))}
        </div>
      )}
    </div>
  );
}

function OrganizationHierarchy({ data }) {
  return (
    <div className="organization">
      <Employee empId={data[0].id} employees={data} />
    </div>
  );
}

export default OrganizationHierarchy;

/* import React, { useState } from 'react';
import './OrganizationHierarchy.css';  // Assuming you have some basic styles defined in Hierarchy.css

function findEmployeeById(empId, employees) {
  return employees.find(emp => emp.id === empId);
}

function Employee({ empId, employees }) {
  const [isOpen, setIsOpen] = useState(true);
  const employee = findEmployeeById(empId, employees);

  if (!employee) return null;

  return (
    <div className="employee">
      <div className="employee-card" onClick={() => setIsOpen(!isOpen)}>
        <img src={employee.imageUrl} alt={employee.name} />
        <div className="employee-info">
          <h3>{employee.name}</h3>
          <p>{employee.designation}</p>
          <div className={isOpen ? "description" : "description"}>{employee.description}</div>
        </div>
      </div>
      {isOpen && employee.subordinates.length > 0 && (
        <div className="subordinates">
          {employee.subordinates.map(subId => (
            <Employee key={subId} empId={subId} employees={employees} />
          ))}
        </div>
      )}
    </div>
  );
}


function OrganizationHierarchy({ data }) {
    
  return (
    <div className="organization">
      <Employee empId={data[0].id} employees={data} />
    </div>
  );
}

export default OrganizationHierarchy;
 */
