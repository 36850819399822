// Footer.js
import React from "react"; /* 
import { Link } from "react-router-dom"; */
import linkedin from "../images/icons/linkedin.png";
import facebook from "../images/icons/facebook.png";
import x from "../images/icons/x.png";
import "./Footer.css"; // Create a separate CSS file for styling
import { useLocation } from "react-router-dom";

const Footer = () => {
  const location = useLocation();
  const handleNavigation = (path, hash = "") => {
    /* setShow(false); */
    /* vis(false); */
    // Navigate to the path
    window.location.href = path + hash;

    // Scroll to the section if already on the ProductPage
    if (location.pathname === path && hash) {
      const element = document.getElementById(hash.substring(1));
      if (element) {
        element.scrollIntoView({ behavior: "smooth" });
      }
    }
  };
  return (
    <footer className="footer">
      <div className="company-info">
        <div>
          <h3>SANSYS Informatics</h3>
          <p>
            Transform Your Healthcare Operations with SANSYS Informatics Pvt.
            Ltd. At SANSYS Informatics Pvt. Ltd., we deliver cutting-edge
            integrated solutions in Healthcare HIS, EMR, and ERP. Our bespoke
            SANSYS HIMS & EHR solution combines affordability with exceptional,
            reliable support.
          </p>
          <p>
            We excel in crafting tailored solutions with our expertise in
            design, customization, implementation, integration, data migration,
            and ongoing support. Our innovative web-based UI of complete
            Healthcare Suite has been meticulously developed to not only meet
            but exceed client expectations, driving superior usability and
            enhancing clinical adoption.
          </p>
          <p>
            <b>
              {" "}
              Partner with us to revolutionize your healthcare operations with
              solutions that are as advanced as they are dependable.
            </b>
          </p>
        </div>
        <div className="social-media">
          <a href="#id" className="social-link">
            <img src={linkedin} alt="" />
          </a>
          <a href="#id" className="social-link">
            <img src={x} alt="" />
          </a>
          <a href="#id" className="social-link">
            <img src={facebook} alt="" />
          </a>
        </div>
      </div>
      <div className="services-footer">
        <h4>Services</h4>
        <ul style={{ paddingLeft: "0px" }}>
          <li>
            <div
              className="footer-links"
              onClick={() =>
                handleNavigation(
                  "/service",
                  "#sansys-applicatin-implementation"
                )
              }
            >
              <span className="hide-on-mobile">&#9656;</span> Application
              Implementation Services
            </div>
            {/* <Link to="/service#" className="footer-links">
              Application Implementation Services
            </div> */}
          </li>
          <li>
            <div
              className="footer-links"
              onClick={() =>
                handleNavigation("/service", "#sansys-clinical-transformation")
              }
            >
              <span className="hide-on-mobile">&#9656;</span>
              Clinical Transformation
            </div>
          </li>
          <li>
            <div
              className="footer-links"
              onClick={() =>
                handleNavigation("/service", "#sansys-application-integration")
              }
            >
              <span className="hide-on-mobile">&#9656;</span> Application
              Integration Services
            </div>
          </li>
          <li>
            <div
              className="footer-links"
              onClick={() =>
                handleNavigation("/service", "#sansys-data-migration")
              }
            >
              <span className="hide-on-mobile">&#9656;</span>
              Data Migration Services
            </div>
          </li>
          <li>
            <div
              className="footer-links"
              onClick={() =>
                handleNavigation("/service", "#sansys-iot-integration")
              }
            >
              <span className="hide-on-mobile">&#9656;</span> IoT Integration
              Services
            </div>
          </li>
          <li>
            <div
              className="footer-links"
              onClick={() =>
                handleNavigation("/service", "#sansys-equipment-interfacing")
              }
            >
              <span className="hide-on-mobile">&#9656;</span> Equipment
              Interfacing
            </div>
          </li>
          <li>
            <div
              className="footer-links"
              onClick={() =>
                handleNavigation("/service", "#sansys-mobile-apps")
              }
            >
              <span className="hide-on-mobile">&#9656;</span> Mobile
              Applications
            </div>
          </li>
          <li>
            <div
              className="footer-links"
              onClick={() => handleNavigation("/service", "#sansys-training")}
            >
              <span className="hide-on-mobile">&#9656;</span> Product Training
              Services
            </div>
          </li>
          <li>
            <div
              className="footer-links"
              onClick={() => handleNavigation("/service", "#sansys-support")}
            >
              <span className="hide-on-mobile">&#9656;</span>24x7 Expert Support
              Services
            </div>
          </li>
        </ul>
      </div>
      <div className="products-footer">
        <h4>Products</h4>
        <ul style={{ paddingLeft: "0px" }}>
          <li>
            <div
              className="footer-links"
              onClick={() => handleNavigation("/product", "#sansys-hims")}
            >
              <span className="hide-on-mobile">&#9656;</span>
              Sansys HIMS
            </div>
          </li>
          <li>
            <div
              className="footer-links"
              onClick={() => handleNavigation("/product", "#sansys-ehr")}
            >
              <span className="hide-on-mobile">&#9656;</span>
              Sansys EHR
            </div>
          </li>
          <li>
            <div
              className="footer-links"
              onClick={() => handleNavigation("/product", "#sansys-bbms")}
            >
              <span className="hide-on-mobile">&#9656;</span>
              Sansys Blood Bank Management System
            </div>
          </li>
          <li>
            <div
              className="footer-links"
              onClick={() => handleNavigation("/product", "#sansys-onco")}
            >
              <span className="hide-on-mobile">&#9656;</span>
              Sansys Onco Care
            </div>
          </li>
          <li>
            <div
              className="footer-links"
              onClick={() => handleNavigation("/product", "#sansys-nurCare")}
            >
              <span className="hide-on-mobile">&#9656;</span>
              Sansys Nursing Care
            </div>
          </li>
          <li>
            <div
              className="footer-links"
              onClick={() => handleNavigation("/product", "#sansys-emerCare")}
            >
              <span className="hide-on-mobile">&#9656;</span>
              Sansys Emergency Care
            </div>
          </li>
          <li>
            <div
              className="footer-links"
              onClick={() => handleNavigation("/product", "#sansys-lims")}
            >
              <span className="hide-on-mobile">&#9656;</span>
              Sansys Laboratory Information Management System
            </div>
          </li>
          <li>
            <div
              className="footer-links"
              onClick={() => handleNavigation("/product", "#sansys-bi")}
            >
              <span className="hide-on-mobile">&#9656;</span>
              Sansys BI & Research
            </div>
          </li>
          <li>
            <div
              className="footer-links"
              onClick={() => handleNavigation("/product", "#sansys-docApp")}
            >
              <span className="hide-on-mobile">&#9656;</span>
              Sansys Mobile Doctor App
            </div>
          </li>
          <li>
            <div
              className="footer-links"
              onClick={() => handleNavigation("/product", "#sansys-nurApp")}
            >
              <span className="hide-on-mobile">&#9656;</span>
              Sansys Mobile Nursing App
            </div>
          </li>
          <li>
            <div
              className="footer-links"
              onClick={() => handleNavigation("/product", "#sansys-patPortApp")}
            >
              <span className="hide-on-mobile">&#9656;</span>
              Sansys Mobile Patient Portal App
            </div>
          </li>
        </ul>
      </div>
      <div className="contact-section">
        <h4>Contact Us</h4>
        <div className="contact-field">
          <p>
            Branch Office - SANSYS Informatics Pvt. Ltd Branch Office: H No.
            M-7, Jagdamba Arcade, Amritpuri, Awadhpuri, Bhopal-462021 Madhya
            Pradesh-India Contact No.: 0755-3593003, (+91)-9811868230
          </p>
        </div>
        <div className="contact-field">
          <p>Phone - 0755-3593003, (+91)-9811868230</p>
        </div>
        <div className="contact-field">
          <p>Email - info@sansysinformatics.com</p>
        </div>
        <div className="contact-field">
          <p>
            Registered Office - 82, S/F, Back Side Sanjay Enclave, Uttam Nagar,
            New Delhi- 110059
          </p>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
