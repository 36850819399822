import React from 'react';
import Star from './Star';

const Rating = ({ rating }) => {
  const totalStars = 5;
  let stars = [];
  for (let i = 1; i <= totalStars; i++) {
    if (i <= Math.floor(rating)) {
      stars.push(<Star key={i} value={1} />);
    } else if (i - 1 < rating && i > rating) {
      stars.push(<Star key={i} value={0.5} />);
    } else {
      stars.push(<Star key={i} value={0} />);
    }
  }

  return <div>{stars}</div>;
};

export default Rating;