import React, { createContext, useState } from "react";

// Create the context
export const ShowContext = createContext();

// Context provider component
export const ShowProvider = ({ children }) => {
  const [show, setShow] = useState(false);
  const [isProductDropdownVisible, setIsProductDropdownVisible] =
    useState(false);
  const [isProductVisible, setIsProductVisible] = useState(false);
  const [isServiceVisible, setIsServiceVisible] = useState(false);
  const [isAboutUsVisible, setIsAboutUsVisible] = useState(false);
  return (
    <ShowContext.Provider
      value={{
        show,
        setShow,
        isProductDropdownVisible,
        setIsProductDropdownVisible,
        isProductVisible,
        setIsProductVisible,
        isServiceVisible,
        setIsServiceVisible,
        isAboutUsVisible,
        setIsAboutUsVisible,
      }}
    >
      {children}
    </ShowContext.Provider>
  );
};
