import React from "react";
import "./Tile.css";
import { motion } from "framer-motion";
import BenefitCard from "./BenefitsCard";
// Tile component which takes title, descriptions (array), image, and an index for layout determination
const Tile = ({
  title,
  subtitle,
  descriptions,
  benefits,
  image,
  index,
  id,
  pgName,
}) => {
  // Determine the order based on the index
  const isEven = index % 2 === 0;
  return (
    <motion.div
      id={id}
      className="tile"
      style={{
        display: "flex",
        marginBottom: "20px",
        flexDirection: isEven ? "row-reverse" : "row",
      }}
      initial="hidden"
      whileInView="visible"
      viewport={{ once: true }}
      transition={{ duration: 0.3 }}
      variants={{
        visible: { opacity: 1, translateX: "0%" },
        hidden: { opacity: 0, translateX: "-100%" },
      }}
    >
      <div className="content">
        <div className="content-texts">
          <h2>
            <b>{title}</b>
          </h2>
          <h5>
            <b> {subtitle}</b>
          </h5>
          {descriptions.map((description, i) => (
            <p key={i}>{description}</p>
          ))}
        </div>
        <div
          style={{
            textAlign: "center",
            display: "flex",
            flexDirection: "column",
            rowGap: "0px",
            columnGap: "0px",
          }}
        >
          <div className="benefits-title">
            <div className="h-line"></div>
            <h5>
              {" "}
              {pgName === "ProductPage"
                ? "Market-Leading Value Drivers"
                : "Core Service Strengths"}
            </h5>
            <div className="h-line"></div>
          </div>
          <div
            style={{
              display: "flex",
              flexWrap: "wrap",
              justifyContent: "center", //isEven ? "flex-end" : "flex-start",
              rowGap: "8px",
              columnGap: "8px",
            }}
          >
            {benefits.map((benefit, i) => (
              <BenefitCard text={benefit} />
            ))}
          </div>
        </div>
      </div>
      <motion.div className="thumb" >
        <img src={image} alt={title} />
      </motion.div>
    </motion.div>
  );
};

export default Tile;
