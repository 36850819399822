import React, { useEffect } from "react";
import Tile from "./Tile"; // Import the Tile component
import "./ServicePage.css";

import implementation from "../images/services/implementation~2.jpg";/* 
import IOTIntegration from "../images/services/IOT Integration.JPG"; */
import migration from "../images/services/migration~2.jpg";
import mobApp from "../images/services/mobApp~2.jpg";
import iot from "../images/services/IOT2~2.jpg";
import support from "../images/services/support~2.jpg"; /* 
import training from "../images/services/training.jpg"; */
import training2 from "../images/services/training2~2.jpg";
import appIntegration from "../images/services/appIntegration~2.jpg";
import transformation from "../images/services/transformation~2.jpg";
import eqInterface from "../images/services/eqInterface~2.JPG";

const services = [
  {
    title: "Application Implementation Services",
    description: [
      "Our Rapid Implementation Methodology, is specifically designed for the Digital healthcare Enterprise Solution.",
      "We have successfully implemented the Global VistA solution using this methodology, completing a project for a 3,500-bed hospital (AIIMS Delhi) in just three months.",
      "Our implementation process follows a detailed 9-step procedure. We ensure that your current software operations remain uninterrupted throughout the process. Additionally, we provide comprehensive training for your staff, ensuring they are fully prepared to operate the new system once it goes live.",
    ],
    subDes: "",
    benefits: [
      "Seamless Integration",
      "Tailored Solutions",
      "Faster Deployment",
      "Reduced Risk",
    ],
    image: implementation,
    id: "sansys-applicatin-implementation",
  },
  {
    title: "Clinical Transformation",
    description: [
      "A comprehensive approach to improving patient care across all hospital levels, powered by cutting-edge technologies that empower healthcare professionals with seamless access, connectivity, electronic health record data integration and robust backup systems.",

      "At Sansys Informatics, we are committed to achieving care quality, safety and workflow transformation by rejecting inefficient practices and embracing a unified goal of patient safety, clinical excellence, and quality care, we strive for sustainable transformation.",
      /* 
      "Our approach combines the strengths of people, processes, and technology to drive transformation across facilities, departments, and clinical specialties. Through strategic process redesign and IT implementation, we enable healthcare organizations to achieve:",
      "- Improved patient safety and outcomes",
      "- Enhanced clinical quality and efficiency",
      "- Optimized workflows and resource allocation",
      "Join us in shaping the future of healthcare through Clinical Transformation!", */
    ],
    subDes:
      "Join us in shaping the future of healthcare through Clinical Transformation!",
    benefits: [
      "Improved Patient Outcomes",
      "Increased Efficiency",
      "Evidence-based Practices",
      "Change Management",
    ],
    image: transformation,
    id: "sansys-clinical-transformation",
  },
  {
    title: "Application Integration Services",
    description: [
      "At Sansys Informatics Pvt. Ltd., we offer specialized HL7 interface engine services tailored for the healthcare industry. Our HL7 interface engine connects legacy systems using a standard messaging protocol, ensuring seamless communication between different systems within hospitals and healthcare providers.",
      "Given the diverse systems for various services, communication challenges often arise. Our HL7 interface engine overcomes these challenges by providing a robust framework for the exchange, integration, sharing, and retrieval of electronic health information. These standards are widely recognized and used globally.",
      "Our HL7 interface engines function as intermediaries between different systems, monitoring various interfaces and communication points, and performing actions based on organizational rules. They work with several standards, including Conceptual Standards, Document Standards, Application Standards, and Messaging Standards. Specifically, messaging standards define how information is packaged and communicated from one system to another, ensuring smooth and efficient data exchange.",
    ],
    benefits: [
      "Enhanced Connectivity",
      "Improved Data Flow",
      "Operational Efficiency",
      "Scalability",
    ],
    image: appIntegration,
    id: "sansys-application-integration",
  },
  {
    title: "Data Migration Services",
    description: [
      "At Sansys Informatics Pvt. Ltd., we offer exceptional data migration services designed to seamlessly transfer multiple data sets with minimal time consumption. Our versatile Global VistA EHR solution ensures:",
      /* "High Success Rates:  Achieve stellar success rates in data migration projects.",
      "Comprehensive Data Availability: Ensure all historical data, including electronic health record data, is available in new applications.",
      "Rapid Execution and Cost Efficiency: Execute data migrations swiftly and at a lower cost.",
      "Value-Based Data Migration: Migrate data based on its value, reducing the risk of failure.",
      "Smooth Acceptance Testing: Eliminate surprises during acceptance testing.",
       */ "Our solution guarantees that the data you expect is accurately transferred to the new application, including Electronic Medical Records. With our advanced technology, migrating data from your database to our software not only physically moves the data but also ensures its integrity and accuracy.",
      "Our EHR system is robust and scalable, capable of transferring or merging multiple data sets quickly. For instance, at AIIMS Delhi, we successfully transferred 1.5 million patient records in just 3 hours — a testament to our efficiency and expertise.",
    ],
    subDes:
      "Experience seamless data migration with Sansys Informatics Pvt. Ltd., where your data integrity and success are our top priorities.",
    benefits: [
      "High Success Rates",
      "Comprehensive Data Availability",
      "Rapid Execution and Cost Efficiency",
      "Value-Based Data Migration",
      "Smooth Acceptance Testing",
    ],
    image: migration,
    id: "sansys-data-migration",
  },

  {
    title: "IoT Integration Services",
    description: [
      "In today’s connected world, the Internet of Things (IoT) is transforming the healthcare industry by enabling real-time monitoring and data exchange. At Sansys Informatics Pvt. Ltd., our IoT Integration Services are designed to seamlessly connect medical devices, sensors, and systems, providing a comprehensive, real-time view of patient health.",
    ],
    benefits: [
      "Real-time Monitoring",
      "Enhanced Data Collection",
      "Improved Operational Efficiency",
    ],
    image: iot,
    id: "sansys-iot-integration",
  },
  {
    title: "Equipment Interfacing",
    description: [
      "In a healthcare environment, the seamless operation of medical equipment is crucial. Our Equipment Interfacing services ensure that all your medical devices are interconnected and functioning efficiently within your IT infrastructure. This integration leads to better data accuracy, streamlined workflows, and enhanced patient safety.",
    ],
    benefits: ["Unified System", "Reduced Errors", "Enhanced Workflow"],
    image: eqInterface,
    id: "sansys-equipment-interfacing",
  },
  {
    title: "Mobile Applications",
    description: [
      "In the era of mobile technology, providing accessible and efficient healthcare services is paramount. Our Mobile Applications service offers customized mobile solutions for healthcare providers, nurses, and patients, ensuring that critical information is always at their fingertips.",
      "Embrace the future of healthcare with our mobile applications, designed to improve efficiency, enhance patient care, and foster better communication across your organization.",
    ],
    benefits: ["For Healthcare Providers", "For Nursing Staff", "For Patients"],
    image: mobApp,
    id: "sansys-mobile-apps",
  },
  {
    title: "Product Training Services",
    description: [
      "Many healthcare organizations struggle with EHR adoption despite overcoming initial cost barriers. Prolonged implementation times, inadequate training, and insufficient post-installation support can render many solutions ineffective. Our client services are an integral component of the Global VistA EMR solution, designed to maximize its organizational impact.",
      "Our training services focus on enterprise adoption, user retention, and overall client satisfaction. The training begins with formal classroom instruction and core competency testing, supplemented by our robust Go Live support program. During this critical period, our experts work side-by-side with your staff, helping them become comfortable and confident with the Global VistA EMR platform. We also offer ongoing training programs to address additional training needs.",
    ],
    benefits: [
      "Increased Proficiency",
      "Customized Training",
      "Improved Adoption",
      "Ongoing Support",
    ],
    image: training2,
    id: "sansys-training",
  },
  {
    title: "24x7 Expert Support Services",
    description: [
      "We provide reliable support to ensure the success of your EHR investment. Our support services offer responsive application assistance, timely updates, and enhanced system security and reliability. We offer 24x7 support services, including patch management, version control, and system optimization, ensuring that your Global VistA EMR system remains efficient and up-to-date.",
      "",
    ],
    subDes:
      "Choose Sansys Informatics Pvt. Ltd. for comprehensive training and support services, and experience the full potential of the Global VistA EMR platform.",
    benefits: [
      "Uninterrupted Assistance",
      "Proactive Monitoring",
      "Enhanced Reliability",
      "Peace of Mind",
    ],
    image: support,
    id: "sansys-support",
  },
];
const ServicePage = () => {
  useEffect(() => {
    const handleHashChange = () => {
      const hash = window.location.hash.substring(1);
      if (hash) {
        const element = document.getElementById(hash);
        if (element) {
          element.scrollIntoView({ behavior: "smooth" });
        }
      }
    }; // Scroll to the hash on mount
    handleHashChange();

    // Add hashchange event listener
    window.addEventListener("hashchange", handleHashChange);

    // Cleanup event listener on unmount
    return () => {
      window.removeEventListener("hashchange", handleHashChange);
    };
  }, []);
  return (
    <div>
      <div className="product-intro">
        Unlock the potential of your healthcare operations with our
        transformative and comprehensive services.
      </div>
      {services.map((service, index) => (
        <Tile
          key={index}
          title={service.title}
          descriptions={service.description}
          benefits={service.benefits}
          image={service.image}
          index={index}
          id={service.id}
          pgName={"ServicePage"}
        />
      ))}
    </div>
  );
};

export default ServicePage;
