import React, { useEffect } from 'react';
import { MapContainer, TileLayer, Marker, Tooltip, useMap, useMapEvents } from 'react-leaflet';
import L from 'leaflet';
import 'leaflet/dist/leaflet.css';
import './MapComponent.css'

// Import marker images
import iconUrl from 'leaflet/dist/images/marker-icon.png';
import iconRetinaUrl from 'leaflet/dist/images/marker-icon-2x.png';
import shadowUrl from 'leaflet/dist/images/marker-shadow.png';

// Create a custom icon
const customIcon = new L.Icon({
  iconUrl: iconUrl,
  iconRetinaUrl: iconRetinaUrl,
  shadowUrl: shadowUrl,
  iconSize: [25, 41],
  iconAnchor: [12, 41],
  popupAnchor: [1, -34],
  shadowSize: [41, 41]
});

const MapComponent = ({ locations }) => {
  const center = [20.5937, 78.9629];

  const MapZoomHandler = () => {
    const map = useMap();
    useMapEvents({
      zoomend: () => {
        const currentZoom = map.getZoom();
        if (currentZoom >= 5) {
          map.dragging.enable();
        } else {
          map.dragging.disable();
        }
      }
    });

    useEffect(() => {
      // Initially disable dragging
      map.dragging.disable();
    }, [map]);

    return null;
  };

  const bounds = L.latLngBounds(locations.map(loc => loc.coordinates));

  return (
    <div className='map-component'>
      <MapContainer 
        center={center} 
        zoom={4} 
        minZoom={4}
        scrollWheelZoom={true}
        className='map-container'
        maxBounds={bounds}
        maxBoundsViscosity={0.5}
      >
        <TileLayer
          url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
          attribution="&copy; <a href='https://www.openstreetmap.org/copyright'>OpenStreetMap</a> contributors"
        />
        {locations.map((location, index) => (
          <Marker key={index} position={location.coordinates} icon={customIcon}>
            <Tooltip>{location.name}</Tooltip>
          </Marker>
        ))}
        <MapZoomHandler />
      </MapContainer>
    </div>
  );
};

export default MapComponent;
