import React from "react";
import { Carousel } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import "./Events.css";

import img1 from "../images/events1.jpg";
import img2 from "../images/events2.jpg";/* 
import img3 from "../images/events3.jpg"; */
const images = [ img1, img2];

const items = [
  {
    title: "Upcoming Events",
    events: [
      "Webinar - Healthcare Innovation -AI on 27th Sept 2024",
      "Webinar -Healthcare Technology on 13th Sept 2024",
    ],
  },
  {
    title: "Birthdays & Celebrations",
    events: [
      "Successful Implementation of SANSYS EHR at -Muthood HSP PVT LTD MAX DWARKA",
      "Successful Implementation of SANSYS Blood Bank Management System at  MAX-SAHARA LUCKNOW"
    ],
  },
];
const Events = () => {
  return (
    <div className="events">
      <Carousel
        controls={false}
        indicators={false}
        interval={3000}
        pause={"hover"}
        fade
        className="custom-carousel"
      >
        {images.map((image, index) => (
          <Carousel.Item
            key={index} /* 
      className={index === 2 ? "stretch-bg" : ""} */
            style={{
              position: "relative",
              width: "100%",
              height: "100vh",
              overflow: "hidden",
            }}
          >
            <div
              className="carousel-bg-events"
              style={{
                backgroundImage: `url(${image})`,
              }}
            >
              {/* <div className="carousel-caption">
                <h2>{image.heading}</h2>
                <p
                  style={{
                    color: "white",
                    fontSize: "24px",
                    marginTop: "4px",
                  }}
                >
                  {image.text}
                </p>
              </div> */}
            </div>
          </Carousel.Item>
        ))}
      </Carousel>
      <div className="events-contents">
        <div className="events-text">
          At Sansys Informatics, we cherish the spirit of togetherness and the
          joy that comes from shared moments. This vibrant culture transforms
          Sansys into more than just a workplace—We're a thriving community !
        </div>
        <div className="events-carousel">
          <Carousel
            controls={false}
            indicators={true}
            interval={3000}
            pause={"hover"}
            fade
            className="custom-events-carousel"
          >
            {items.map((item, index) => (
              <Carousel.Item
                key={index} /* 
      className={index === 2 ? "stretch-bg" : ""} */
                style={{
                  position: "relative",
                  width: "100%",
                  height: "100%",
                  overflow: "hidden",
                }}
              >
                <div className="carousel-events-backgrounds">
                  <div className="carousel-events-actions">
                    <h2>{item.title}</h2>
                    <p
                      style={{
                        color: "white",
                        fontSize: "24px",
                        marginTop: "4px",
                      }}
                    >
                      {item.events.map((event, index) => (
                        <li>{event}</li>
                      ))}
                    </p>
                  </div>
                </div>
              </Carousel.Item>
            ))}
          </Carousel>
        </div>
      </div>
    </div>
  );
};

export default Events;
