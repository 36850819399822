import { useParams, useNavigate } from "react-router-dom";
import person from "../images/person.png";
import "./MemberDetail.css";
const leaders = [
    {
      name: "mr-santosh-mishra",
      title: "Mr. Santosh Mishra",
      designation: "CEO",
      description: [
        "	Santosh Mishra serves as the Chief Executive Officer and the Founder of Sansys Informatics. His passion for the development of world class products and use of latest technology is a driving force behind Sansys Informatics. He started the company with a vision to make EHR a very affordable entity for the Indian Healthcare Industry. With more than a decade of experience in the software industry and having worked for multiple healthcare domains, he has immense knowledge of the EHR Technologies and so much so that he has single handedly implemented EHR in such a way that it has become a revolution in the biggest public sector hospitals AIIMS Delhi and AIIMS Bhopal.",
        "	Prior to this he was leading the division for EHR Integration with MobileCare application at Agnity. He was a key member of Implementation and Integration of WorldVistA EHR for Rajeev Gandhi Cancer Institute, New Delhi, Military Hospital and Cardiothoracic Center, Pune. He has also worked as a Subject Matter Expert for VistA Implementation in Max Hospital.",
      ],
      logoImg: person,
  
      rating: 4.5,
    },
    {
      name: "dr-syed-tirmizi",
      title: "Dr. Syed Tirmizi (MD, CPHQ)",
      designation: "Director",
      description: [
        "	Dr. Syed Tirmizi is an internationally recognized medical informatician Since 1990, he worked at a number of Veterans Health Administration (VHA) hospitals in America as Chief of Medicine & Ambulatory Care and later as Senior Medical Informatician at the VHA headquarters in Washington, DC.",
        "	He collaborated with a number of leading University Hospitals to share the VHA medical informatics best practices including VistA-CPRS. He was also the Program Director of the Medical Informatics Fellowships offered at eight VHA Hospitals affiliated with leading Universities and represented the VHA at the National Library of Medicine Medical Informatics annual conferences.",
        "	Dr. Tirmizi was a keynote speaker at a number of international conferences including in the European Union, Middle-East and the Asia Pacific Region. He presented the VHA developed EHR called VistA-CPRS to a number of international delegations interested in adopting this open source EHR in their countries. He supervised the deployment of VistA-CPRS at Max Healthcare network and Rajiv Gandhi Cancer Hospitals in New Delhi. Max Hospitals were designated by HIMSS as first Stage 6 implementation in India back in 2012.Dr. Tirmizi was a keynote speaker at a number of international conferences including in the European Union, Middle-East and the Asia Pacific Region. He presented the VHA developed EHR called VistA-CPRS to a number of international delegations interested in adopting this open source EHR in their countries. He supervised the deployment of VistA-CPRS at Max Healthcare network and Rajiv Gandhi Cancer Hospitals in India. Back in 2012, Max Hospitals were designated by HIMSS as Stage 6 implementation.",
        " Dr. Tirmizi promoted a culture of Patient Safety internationally as Senior VP at Quantros – a Patient Safety Organization and later as a consultant with Pascal metrics – another Patient Safety Organization.",
        " Dr. Tirmizi is particularly interested in use of Artificial Intelligence in Healthcare to enable better, safer and efficient care. Recently he participated in the US Government Summit on AI in Healthcare and reviewed the AI roll-out strategy.",
        " Dr. Tirmizi was faculty at Texas Tech University of Health Sciences, Uniformed Services University of Health Sciences and George Mason University. He developed online Health Informatics courses on EMR, Clinical Decision Support, Change Management etc.",
      ],
      logoImg: person,
  
      rating: 4.5,
    },
  
    {
      name: "mr-dinesh-samudra",
      title: "Mr. Dinesh Samudra",
      designation: "Principal Consultant -Sales & Marketing",
      description: [
        "	Dinesh Samudra truly believes in transforming the way care is delivered by digitizing healthcare solutions. Highly driven by his passion, he embarked on a journey to develop and design healthcare solutions that would and Co-founder and CEO of Multiple Healthcare IT Organisations, improve care management by providing an innovative and patient-centric SaaS-based platform for better collaboration between healthcare providers and consumers.",
        "	With 32 years of experience in Information Technology and 21 years in Healthcare Dinesh brings to the forefront, his global expertise and knowledge in Healthcare and Health-Tech. His forte lies in conceptualising and building software solutions by mapping procedures and processes of healthcare institutions. In order to maintain service consistency, Dinesh has managed many complex operational arenas and helped healthcare organisations keep pace with medical advancements.",
        " For more than 21 years, Mr. Dinesh provided healthcare solutions to large companies and governments globally. He has worked with hospital leaders to help educate the Board about the complexities, risks, the intricacies of delivering the highest quality of care using the latest technology. He has been consulting with various medical technology services, organisations and health care leaders to discover advancements that reshape and improve caregiving practices and policies. Successfully lead individual departments, functions, projects, partnerships and service lines in Healthcare businesses."
      ],
      logoImg: person,
  
      rating: 4.5,
    },
    {
      name: "mr-uday-pratap",
      title: "Mr. Uday Pratap",
      designation: "VP-Implementation",
      description: [
        "As Vice President of Product Management, Uday Pratap is responsible for the product strategy, direction setting, and channel partner development for Sansys Informatics. In addition, he is responsible for the expansion of Services business in the healthcare domain.",
        "With more than a decade of experience in the software industry and worked for healthcare domains ,Uday has vast knowledge of EHR and he played a vital role in many big implementation like Rajeev Gandhi Cancer Hospital,Max Group of hospital.He also worked for Dell as a SME for CPRS and Radiology. He worked on best EMR in market like Epic and VistA.He is certified in EPIC Ambulatory.",
      ],
      logoImg: person,
  
      rating: 4.5,
    },
    {
      name: "dr-satbir-singh-negi",
      title: "Dr. Satbir Singh Negi",
      designation: "Consultant",
      description: [
        "	Dr. Satbir Singh Negi serves as the Consultant - Corporate Strategy on the Management Board and plays an important role is the direction and vision of the company's growth. He comes with a young experience of 12 years in healthcare domain including clinical, technical and analytics, having worked with technology giants like Oracle, Xavient etc",
        "	Having successfully implemented HMIS and EMR in India, China and USA, now he is focused on the next generation healthcare management by smart and technologically connected and integrated systems on a Global scale.",
      ],
      logoImg: person,
  
      rating: 4.5,
    },
];
const MemberDetail = () => {
  const { name } = useParams();
  const navigate = useNavigate();
  const member = leaders.find((leader) => leader.name === name);

  if (!member) {
    return <div>Member not found</div>;
  }

  return (
    <div className="member-desc">
      <div className="member-thumb">
        <div className="member-image">
          <img src={member.logoImg} alt={member.title} />
        </div>
      </div>
      <div className="member-desc-content">
        <div className="member-title">
          <h2>{member.title}</h2>
        </div>
        <div className="member-designation">
          <h5>{member.designation}</h5>
        </div>
        <div className="member-text">
          {member.description.map((description, i) => (
            <p key={i}>{description}</p>
          ))}
        </div>
        {/* <div className="read-more" onClick={() => navigate(-1)}>
          &larr;&nbsp;&nbsp;Go back
        </div> */}
        {/* 
        <button onClick={() => navigate(-1)}>Go Back</button> */}
      </div>
      <div className="go-back">
        <button onClick={() => navigate(-1)}>Go Back</button>
      </div>
    </div>
  );
};

export default MemberDetail;
