import React from "react";
import { Carousel } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import prod from "../images/prod.jpg"; 
import ser from "../images/ser.jpg";
import com from "../images/comp.jpg";
import cli from "../images/clients/clientsLogo.jpg";
import cli2 from "../images/cliVertical.png";
/* 
import cli2 from "../images/cli2.png"; */
import test from "../images/test.jpg";
import "./Hero2.css";
import Statistics from "./statistics";
import { useMediaQuery } from "react-responsive"; 

const images = [
  {
    src: prod,
    heading: "Products",
    text: "Revolutionizing Healthcare with Seamless, Secure, and Smart EHR Solutions",
  },
  {
    src: ser,
    heading: "Services",
    text: "Transforming Healthcare with Innovative EHR Technology with 24x7 support",
  },
  {
    src: cli,
    heading: "Our Clients",
    text: "Empowering Your Health Journey with Compassionate Care and Cutting-Edge Technology",
  },
  /* {
    src: cli2,
    heading: "Our Clients",
    text: "Empowering Your Health Journey with Compassionate Care and Cutting-Edge Technology",
  }, */
  {
    src: com,
    heading: "",
    text: "Driving Healthcare Excellence with Advanced EHR and HIMS Solutions",
  },
  {
    src: test,
    heading: "Testimonials",
    text: "Voice of Trust: Reliable Solutions, Happy Clients ! Elevating Healthcare Together",
  },
];

const FullWidthCarousel = () => {
  
  // Define media queries
  const isMobile = useMediaQuery({ query: "(max-width: 767px)" });
  return (
    <div style={{ paddingTop: "0px" }}>
      <Carousel
        controls={false}
        indicators={true}
        interval={3000}
        pause={"hover"} 
        fade
        className="custom-carousel"
      >
        {images.map((image, index) => (
          <Carousel.Item
            key={index}
            className={index === 2 ? "stretch-bg" : ""}
            style={{
              position: "relative",
              width: "100%",
              height: "100vh",
              overflow: "hidden",
            }}
          >
            <div
              className="carousel-bg"
              style={{
                backgroundImage: (isMobile&&index===2) ? `url(${cli2})` : `url(${image.src})`,
              }}
            >
              <div className="carousel-caption">
                <h2>{image.heading}</h2>
                <p
                  style={{
                    color: "white",
                    fontSize: "24px",
                    marginTop: "4px",
                  }}
                >
                  {image.text}
                </p>
              </div>
            </div>
          </Carousel.Item>
        ))}
      </Carousel>
      <div className="stats">
        <Statistics />
      </div>
    </div>
  );
};

export default FullWidthCarousel;
