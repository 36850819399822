import TileClient from "./TileClient";
import maxblk from "../images/clients/max-blk.jpg";
import maxbhatinda from "../images/clients/max-bhatinda.jpg";
import maxdehradun from "../images/clients/max-dehradun.png";
import maxdelhi from "../images/clients/max-delhi.png";
import maxgurugram from "../images/clients/max-gurugram.jpg";
import maxlajpatnagar from "../images/clients/max-lajpatnagar.png";
import maxlucknow from "../images/clients/max-lucknow.png";
import maxmohali from "../images/clients/max-mohali.jpg";
import maxnagpur from "../images/clients/max-nagpur.jpeg";
import maxnanavati from "../images/clients/max-nanavati-mumbai.png";
import maxnoida from "../images/clients/max-noida.png";
import maxpanchsheel from "../images/clients/max-panchsheel.jpg";
import maxpatparganj from "../images/clients/max-patparganj.jpg";
import maxsaket from "../images/clients/max-saket.png";
import maxsmart from "../images/clients/max-smart.jpg";
import maxvsh from "../images/clients/max-vsh.jpg";
import maxLogo from "../images/clients/maxLogo.png";

import aiims from "../images/clients/Others/aiims.jpg";
import jpnatc from "../images/clients/Others/jpnatc.jpg";
import aimsLogo from "../images/clients/aimsLogo.png";

import rgci from "../images/clients/Others/rgci.jpg";
import rgciLogo from "../images/clients/rgciLogo.png";

import kims from "../images/clients/Others/kims.jpg";
import kimsLogo from "../images/clients/kimsLogo.png";

import lnh from "../images/clients/Others/lnjpdel.jpg";
import lnhLogo from "../images/clients/lnhLogo.png";

import lhc from "../images/clients/Others/lhc.jpeg";
import lhcLogo from "../images/clients/lhcLogo.png";

import ics from "../images/clients/Others/icsndelhi.png";
import icsLogo from "../images/clients/icsLogo.png";

import ssphpgti from "../images/clients/Others/ssphpgti.jpg";
import sspghtiLogo from "../images/clients/sspghtiLogo.png";

import nch from "../images/clients/Others/nchrc.png";
import nchLogo from "../images/clients/nchLogo.png";

import cmc from "../images/clients/Others/cmc.jpg";
import cmcLogo from "../images/clients/cmcLogo.png";
import "./Clients.css"; /* 
import { useState } from "react"; */
const clients = [
  {
    title: "MAX Healthcare",
    description: [
      "	The implementation of Sansys’ innovative solutions, including <strong>Sansys EHR, Sansys BCMA, Sansys NDRS, Sansys Clinical Pharmacy, and Sansys BBMS</strong>, across all our hospitals has truly transformed the way we deliver healthcare at Max Healthcare. The seamless integration and intuitive design of these systems have significantly improved our operational efficiency, enabling our medical teams to focus more on patient care. The support from the Sansys team has been exceptional, providing us with the training and resources needed to fully leverage the capabilities of these solutions. We’ve seen a marked improvement in patient outcomes, and the feedback from our staff has been overwhelmingly positive.	",
      "	Sansys Informatics has proven to be a reliable partner in our journey towards digital transformation, and we highly recommend their solutions to any healthcare organization looking to enhance their services.	",
    ],
    logoImg: maxLogo,
    images: [
      maxsaket,
      maxsmart,
      maxvsh,
      maxblk,
      maxbhatinda,
      maxdehradun,
      maxdelhi,
      maxgurugram,
      maxlajpatnagar,
      maxlucknow,
      maxmohali,
      maxnagpur,
      maxnanavati,
      maxnoida,
      maxpanchsheel,
      maxpatparganj,
    ], // maxLogo
    rating: 5,
  },
  {
    title: "BLK-MAX",
    description: [
      "	The implementation of Sansys’ innovative solutions, including <strong>Sansys EHR, Sansys BCMA, Sansys NDRS, Sansys Clinical Pharmacy, and Sansys BBMS</strong>, at BLK-MAX Hospital has profoundly transformed our healthcare operations. The seamless integration and intuitive design of these systems have significantly improved our operational efficiency, enabling our medical teams to deliver more focused and effective patient care. 	",
      "	The advanced features of <strong>Sansys BBMS</strong> have revolutionized our blood bank operations, optimizing inventory management and ensuring the highest standards of blood safety and availability. With the support and training from the Sansys team, we have seen a remarkable boost in patient outcomes and operational efficiency. 	",
      "	Sansys Informatics has proven to be a reliable partner in our journey towards digital transformation, and we highly recommend their solutions to any healthcare organization looking to enhance their services.	",
    ],
    logoImg: maxLogo,
    images: [maxblk], // maxLogo
    rating: 5,
  },
  {
    title: "Nanavati-MAX",
    description: [
      "	The deployment of <strong>Sansys EHR, Sansys BCMA, Sansys NDRS, Sansys Clinical Pharmacy, and Sansys BBMS</strong> at Nanavati-Max Hospital has significantly enhanced our healthcare delivery. The integration of these systems has streamlined our processes, improved data accuracy, and optimized patient care across our hospital. The advanced functionalities of Sansys BBMS have been particularly impactful, revolutionizing our blood bank operations with improved inventory management and safety. The support from the Sansys team was exceptional, ensuring a smooth implementation and effective utilization of these solutions. 	",
      "	We have observed marked improvements in operational efficiency and patient outcomes, making Sansys Informatics a crucial partner in our mission to provide high-quality healthcare.	",
    ],
    logoImg: maxLogo,
    images: [
      /*  maxblk,
      maxbhatinda,
      maxdehradun,
      maxdelhi,
      maxgurugram,
      maxlajpatnagar,
      maxlucknow,
      maxmohali,
      maxnagpur, */
      maxnanavati,
      /* maxnoida,
      maxpanchsheel,
      maxpatparganj,
      maxsaket,
      maxsmart,
      maxvsh, */
    ], // maxLogo
    rating: 5,
  },
  {
    title: "Jai Prakash Narayan Apex Trauma Center, New Delhi",
    description: [
      "	Integrating <strong>Sansys HIMS</strong> and <strong>Sansys EHR, Emergency Care - eMLC</strong> into our trauma care operations has been a transformative experience. The system's adaptability and efficiency have greatly enhanced our ability to provide critical, time-sensitive care. From streamlined patient data management to improved coordination across departments, Sansys EHR has proven to be an invaluable tool in our daily operations. The support team at Sansys Informatics ensured a flawless implementation and continues to provide exceptional service. 	",
      "	We have seen a significant boost in both our operational capabilities and patient outcomes, making Sansys Informatics an indispensable partner in our trauma care services.	",
    ],
    logoImg: aimsLogo,
    images: [jpnatc], // aimsLogo,
    rating: 4,
  },
  {
    title: "All India Institute of Medical Sciences, New Delhi",
    description: [
      "	Implementing the <strong>Sansys EHR</strong> system at AIIMS New Delhi has been a game-changer for our institution. This comprehensive and user-friendly platform has streamlined our processes, enhanced patient record management, and improved overall care delivery. The transition was smooth, thanks to the expertise and unwavering support from the Sansys team. 	",
      "	We have observed significant improvements in efficiency, and our medical staff can now provide more focused and timely care to our patients. 	",
      "	Sansys Informatics has truly elevated our healthcare operations, and we commend their commitment to excellence and innovation.	",
    ],
    logoImg: aimsLogo,
    images: [aiims], // aimsLogo,
    rating: 5,
  },
  {
    title: "Lok Nayak Government Hospital, Delhi",
    description: [
      "	The implementation of <strong>Sansys HIMS</strong> and <strong>Sansys EHR</strong> at Lok Nayak Government Hospital has greatly enhanced our hospital’s efficiency and patient care capabilities. The system's comprehensive features and user-friendly interface have allowed our teams to manage patient records with greater accuracy and speed. The support and training provided by Sansys Informatics were exemplary, ensuring that our staff could quickly adapt to the new system. 	",
      "	We have experienced a significant reduction in administrative burdens and an improvement in patient outcomes since integrating both of these systems	",
      "	Sansys Informatics has been a trusted partner in modernizing our healthcare services.	",
    ],
    logoImg: lnhLogo,
    images: [lnh], // lnhLogo,
    rating: 5,
  },
  {
    title:
      "Super Speciality Paediatric Hospital & Post Graduate Teaching Institute, Noida",
    description: [
      "	The introduction of Sansys HIMS, <strong>Sansys EHR</strong> at our institute has revolutionized our approach to pediatric care and medical education. The system’s advanced features and intuitive interface have greatly enhanced our ability to manage patient records, streamline workflows, and provide comprehensive care. The implementation was smooth, with the Sansys team providing exceptional support and training to ensure our staff and students could fully utilize the system’s capabilities. 	",
      "	We’ve seen remarkable improvements in the efficiency of our clinical operations and the quality of care we provide. 	",
      "	Sansys Informatics has been a vital partner in elevating both our healthcare delivery and educational standards.	",
    ],
    logoImg: sspghtiLogo,
    images: [ssphpgti], // sspghtiLogo,
    rating: 4,
  },
  {
    title: "Rajiv Gandhi Cancer Institute & Research Centre Rohini, New Delhi",
    description: [
      "	The implementation of <strong>Sansys BBMS, Sansys eConsent Management</strong>, and <strong>Sansys CSSD</strong>, at our institute has significantly enhanced the way we manage and deliver oncology care. The system's robust features and seamless integration have improved our workflows, allowing us to provide more personalized and efficient care to our patients. The support from the Sansys team during and after the implementation has been outstanding, ensuring a smooth transition and continuous optimization of the system. 	",
      "	We have experienced a remarkable improvement in data accuracy, patient management, and overall operational efficiency. 	",
      "	Sansys Informatics has proven to be an invaluable partner in our mission to deliver world-class cancer care.	",
    ],
    logoImg: rgciLogo,
    images: [rgci], // rgciLogo,
    rating: 4,
  },

  {
    title: "Kerala Institute of Medical Sciences (KIMS)",
    description: [
      "	The deployment of <strong>Sansys BBMS</strong> at KIMS has revolutionized our approach to patient care and hospital management. The system’s intuitive interface and advanced functionalities have streamlined our workflows, leading to more efficient and coordinated care across all departments. The implementation process was smooth and well-supported by the Sansys team, who demonstrated a deep understanding of our unique needs. 	",
      "	With these commendable solutions, we have seen marked improvements in data accuracy, patient engagement, and overall operational efficiency. The integration of Sansys BBMS has further enhanced our blood bank operations, optimizing inventory management and ensuring the highest standards of blood safety and availability. 	",
      "	Together, these solutions have significantly elevated the quality of care we provide, making Sansys Informatics an invaluable partner in our quest for excellence in healthcare delivery.	",
    ],
    logoImg: kimsLogo,
    images: [kims], // kimsLogo,
    rating: 5,
  },
  {
    title: "Health City Trauma Centre & Super-speciality Hospital, Lucknow",
    description: [
      "	The integration of <strong>Sansys HIMS</strong> and <strong>Sansys EHR</strong> into our trauma and superspeciality services has been a transformative experience for our hospital. The system’s advanced features and seamless operation have significantly improved our ability to manage patient care, streamline workflows, and enhance communication between departments. The transition was managed expertly by the Sansys team, who provided continuous support and training, ensuring our staff could maximize the benefits of the system from day one. 	",
      "	Since implementing Sansys Solutions, we’ve noticed a marked improvement in patient care and overall hospital efficiency. 	",
      "	Sansys Informatics has been instrumental in helping us achieve our goals for high-quality healthcare delivery.	",
    ],
    logoImg: lhcLogo,
    images: [lhc], // lhcLogo,
    rating: 5,
  },
  {
    title: "Indian Cancer Society, Cancer Treatment Center, New Delhi",
    description: [
      "	Implementing <strong>Sansys HIMS</strong> and <strong>Sansys EHR</strong> at our Cancer Treatment Center has significantly improved our oncology care and overall patient management. The system’s robust features and seamless integration have streamlined our processes, enabling us to better manage patient data, treatment plans, and follow-ups with greater precision. The support from Sansys Informatics during the transition was exceptional, ensuring our team was well-equipped to utilize the system effectively. 	",
      "	Since adopting these innovative solutions by Sansys, we have seen significant improvements in operational efficiency, data accuracy, and patient satisfaction. The ability to provide more personalized and coordinated oncology care has been particularly noteworthy, helping us enhance the quality of life for our patients. 	",
      "	Sansys Informatics has played a crucial role in elevating our standards of cancer care.	",
    ],
    logoImg: icsLogo,
    images: [ics], // icsLogo,
    rating: 5,
  },
  {
    title: "Nepal Cancer Hospital and Research Centre Lalitpur, Nepal",
    description: [
      "	Implementing <strong>Sansys HIMS</strong> and <strong>Sansys EHR</strong> at our hospital has significantly enhanced our oncology care and research capabilities. The system’s comprehensive and user-friendly platform has allowed us to streamline patient data management, improve treatment coordination, and optimize our research processes. The transition to these future ready Solutions was handled with great expertise by the Sansys team, who provided us with continuous support and training. 	",
      "	Since adopting the system, we have observed notable improvements in operational efficiency, data accuracy, and patient care. 	",
      "	Sansys Informatics has been instrumental in helping us advance our mission to provide world-class cancer treatment and research in Nepal.	",
    ],
    logoImg: nchLogo,
    images: [nch], // nchLogo,
    rating: 4,
  },
  {
    title: "Christian Medical College & Hospital, Ludhiana, Punjab",
    description: [
      "	The implementation of <strong>Sansys BBMS</strong> at our institution has profoundly improved our healthcare delivery and administrative efficiency. The system’s powerful features and ease of use have transformed our patient data management, ensuring accuracy and accessibility across all departments. The expertise and support from the Sansys team during the implementation process were exceptional, making the transition seamless for our staff. 	",
      "	We have witnessed significant enhancements in patient care, operational workflows, and overall hospital management. 	",
      "	Sansys Informatics has been a key partner in our journey to provide the highest standards of healthcare.	",
    ],
    logoImg: cmcLogo,
    images: [cmc], // cmcLogo,
    rating: 4,
  },
];

const Clients = () => {
  /* const [maxSubs, setMaxSubs] = useState(false); */
  return (
    <div className="clients-page">
      {clients.map(
        (client, index) => (
          /* maxSubs === true ? ( */
          <TileClient
            key={index}
            title={client.title}
            descriptions={client.description}
            images={client.images}
            logoImg={client.logoImg}
            rating={client.rating}
            index={index}
            /* setMaxSubs={setMaxSubs} */
          />
        )
        /* 
        ) : (
          !client.isSub && (
            <TileClient
              key={index}
              title={client.title}
              descriptions={client.description}
              images={client.images}
              logoImg={client.logoImg}
              rating={client.rating}
              index={index}
              setMaxSubs={setMaxSubs}
            />
          )
        ) */
      )}
    </div>
  );
};

export default Clients;
