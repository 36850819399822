import React, { useEffect } from "react";
import Tile from "./Tile"; // Import the Tile component
import "./ServicePage.css";

import bbms from "../images/products/bbms~2.JPG";
import docApp from "../images/products/docApp2~2.JPG";
import ehr from "../images/products/ehr~2.JPG";
import hims from "../images/products/hims.png";
import nursingApp from "../images/products/nursingApp~2.JPG";
import nursingCare from "../images/products/nursingCare2~2.JPG";
import patientPortal from "../images/products/pp~2.JPG";
import bi from "../images/products/BI~2.JPG";
import emergencyCare from "../images/products/emergencyCare~2.JPG";
import onco from "../images/products/onco~2.jpg";
import lims from "../images/products/lims~2.JPG";
const products = [
  {
    title: "Sansys HIMS",
    subtitle: "Optimize Operations, Enhance Patient Care",
    description: [
      "Our Hospital Information Management System integrates all aspects of hospital management, from patient registration and billing to bed management and discharge. It ensures seamless coordination between departments, reducing administrative overhead and improving patient satisfaction.",
    ],
    benefits: [
      "End-to-End Hospital Management",
      "Integrated Departmental Workflows",
      "Enhanced Patient Experience",
      "Scalable and Customizable",
      "Advanced Billing and Revenue Cycle Management",
      "Real-Time Data Analytics",
      "Compliance with Healthcare Standards",
      "Patient-Centric Design",
    ],
    image: hims,
    id: "sansys-hims",
  },
  {
    title: "Sansys EHR",
    subtitle: "Empower Healthcare Providers with Comprehensive Patient Data",
    description: [
      "Our EHR system provides a secure, comprehensive view of patient medical histories, facilitating better clinical decisions and enhancing patient care. With real-time data access, healthcare providers can deliver personalized treatment plans efficiently.",
    ],
    benefits: [
      "Comprehensive Patient Data Repository",
      "Real-Time Data Accessibility",
      "Interoperable and Scalable",
      "Secure and Compliant Data Management",
      "Customizable Templates and Workflows",
      "Integrated Clinical Decision Support",
      "Efficient Patient Record Search",
      "Multi-Device Accessibility",
    ],
    image: ehr,
    id: "sansys-ehr",
  },
  {
    title: "Sansys Blood Bank Management System",
    subtitle: "Ensure Safe and Efficient Blood Management",
    description: [
      "Our Blood Bank Management System ensures the availability and safety of blood supplies. It manages donor information, blood inventory, and cross-matching processes, guaranteeing compliance with regulatory standards and enhancing patient safety.",
    ],
    benefits: [
      "Advanced Blood Inventory Control",
      "Regulatory Compliance and Safety",
      "Seamless Donor and Recipient Matching",
      "Automated Blood Component Management",
      "Real-Time Inventory Tracking",
      "Integration with Hospital Systems",
      "Comprehensive Donor Database Management",
      "Streamlined Reporting and Auditing",
    ],
    image: bbms,
    id: "sansys-bbms",
  },
  {
    title: "Sansys Onco Care",
    subtitle: "Advanced Oncology Management for Superior Patient Outcomes",
    description: [
      "We understand the complexities and challenges involved in oncology care. Our cutting-edge product, SANSYS Onco Care, is specifically designed to streamline oncology management, enhance patient care, and improve outcomes for cancer patients. Leveraging the advanced capabilities of the our next-gen platform, SANSYS Onco Care is the ultimate solution for comprehensive oncology care.",
    ],
    benefits: [
      "Precision Oncology Management",
      "Multi-Disciplinary Care Coordination",
      "Personalized Treatment Planning",
      "Real-Time Access to Oncology Data",
      "Comprehensive Patient Tracking",
      "Advanced Analytics for Treatment Outcomes",
      "Secure and Compliant Data Handling",
      "Patient Engagement and Education Tools",
    ],
    image: onco,
    id: "sansys-onco",
  },
  {
    title: "Sansys Nursing Care",
    subtitle:
      "Support Your Nursing Staff with our Advanced & User friendly Solutions",
    description: [
      "Our Nursing Care system empowers nursing staff with tools for patient care planning, medication administration, and real-time patient monitoring. It improves communication, reduces errors, and ensures high-quality care.",
      "Our system automates nursing rosters, optimizing shifts and ensuring proper staffing. It also seamlessly integrates with payroll, streamlining processes and guaranteeing accurate compensation.",
      "As digital health evolves, research should define compassion and the skills nurses need to maintain it. At Sansys Informatics, we're meeting this challenge by integrating empathy-driven features into our Nursing Care Systems, empowering nurses to deliver compassionate care in a digital health landscape in the most techno-friendly ways !",
    ],
    benefits: [
      "Automated Nursing Rosters",
      "Optimized Shifts & Staffing",
      "Effortless Integration with Payroll",
      "Optimized Nursing Workflows",
      "Real-Time Patient Monitoring",
      "Comprehensive Care Coordination",
      "Customizable Care Plans",
      "Enhanced Communication with Care Teams",
      "Bar Code Medication Administration Management",
      "Patient Safety and Compliance",
      "Seamless Integration with EHR",
    ],
    image: nursingCare,
    id: "sansys-nurCare",
  },
  {
    title: "Sansys Emergency Care",
    subtitle: "Rapid Response for Critical Situations",
    description: [
      "Our Emergency Care system streamlines emergency department operations, from triage to discharge. It enhances coordination among emergency teams, reduces wait times, and improves patient outcomes in critical situations.",
    ],
    benefits: [
      "Rapid Response and Triage Management",
      "Streamlined Emergency Department Operations",
      "Improved Patient Outcomes",
      "Real-Time Coordination Among Teams",
      "Advanced Alerts and Notification Systems",
      "Integrated Patient Tracking",
      "Immediate Access to Critical Data",
      "Scalable for High-Volume Environments",
    ],
    image: emergencyCare,
    id: "sansys-emerCare",
  },
  {
    title: "Sansys Laboratory Information Management System - (S-LIMS)",
    subtitle: "Enhance Laboratory Efficiency and Accuracy",
    description: [
      "Our SLIM System automates lab workflows, from sample collection to result reporting. It improves data accuracy, reduces turnaround times, and ensures compliance with industry standards, providing reliable support for diagnostic and research activities.",
    ],
    benefits: [
      "Automated Lab Processes",
      "Accurate and Timely Results",
      "Compliance with Industry Standards",
      "Seamless Integration with Hospital Systems",
      "Advanced Specimen Tracking",
      "Comprehensive Data Management",
      "Customizable Reporting Tools",
      "Enhanced Workflow Automation",
    ],
    image: lims,
    id: "sansys-lims",
  },
  {
    title: "Sansys Business Intelligence & Research",
    subtitle: "Unlock Efficient Insights with Advanced Analytics & BI",
    description: [
      "Our Business Intelligence & Research module offers powerful analytics tools to turn data into actionable insights. It supports decision-making, identifies trends, and enhances operational efficiency, driving continuous improvement in your healthcare organization.",
    ],
    benefits: [
      "Data-Driven Decision Making",
      "Advanced Analytics and Reporting",
      "Customizable Dashboards",
      "Predictive Analytics Capabilities",
      "Real-Time Data Insights",
      "Integration with Multiple Data Sources",
      "Comprehensive Research Tools",
      "User-Friendly Interface",
    ],
    image: bi,
    id: "sansys-bi",
  },
  {
    title: "Sansys Doctor's App",
    subtitle: "Empower Physicians with Mobile Access",
    description: [
      "Our Mobile Doctor's App provides physicians with secure access to patient records, test results, and treatment plans on the go. It enhances mobility, improves communication, and allows for better patient care, anytime and anywhere.",
    ],
    benefits: [
      "Mobile Access to Patient Records",
      "Enhanced Physician Mobility",
      "Seamless Communication",
      "Secure Data Access",
      "Real-Time Updates on Patient Status",
      "Customizable Workflows",
      "Integration with EHR Systems",
      "Telemedicine Capabilities",
    ],
    image: docApp,
    id: "sansys-docApp",
  },
  {
    title: "Sansys Nursing App",
    subtitle: "Enhance Nursing Care with Mobile Solutions",
    description: [
      "Our Mobile Nursing App enables nurses to access patient information, update records, and communicate with the care team from mobile devices. It improves efficiency, reduces paperwork, and ensures timely care delivery.",
    ],
    benefits: [
      "Real-Time Care Documentation",
      "Improved Nurse Collaboration",
      "Patient-Centered Nursing Solutions",
      "Medication Administration Support",
      "Enhanced Shift Management",
      "Instant Access to Patient Records",
      "Customizable Nursing Dashboards",
      "Secure and Compliant Communication",
    ],
    image: nursingApp,
    id: "sansys-nurApp",
  },
  {
    title: "Sansys Patient Portal App",
    subtitle: "Engage Patients with Seamless Communication",
    description: [
      "Our Mobile Patient Portal App empowers patients with access to their health information, appointment scheduling, and direct communication with their healthcare providers. It enhances patient engagement, satisfaction, and encourages active participation in their care",
    ],
    benefits: [
      "Patient Empowerment and Engagement",
      "Convenient Health Management",
      "Secure Communication Platform",
      "Easy Appointment Scheduling",
      "Access to Health Records",
      "Real-Time Updates and Notifications",
      "Integrated Payment and Billing",
      "User-Friendly Interface",
    ],
    image: patientPortal,
    id: "sansys-patPortApp",
  },
];
// ServicePage component which lists all services
const ProductPage = () => {
  console.log("Changed Something again");
  useEffect(() => {
    console.log("Changed Something");
    const handleHashChange = () => {
      const hash = window.location.hash.substring(1);
      if (hash) {
        const element = document.getElementById(hash);
        if (element) {
          element.scrollIntoView({ behavior: "smooth" });
        }
      }
    }; // Scroll to the hash on mount
    handleHashChange();

    // Add hashchange event listener
    window.addEventListener("hashchange", handleHashChange);

    // Cleanup event listener on unmount
    return () => {
      window.removeEventListener("hashchange", handleHashChange);
    };
  }, []);
  return (
    <div>
      <div className="product-intro">
        Our Comprehensive Healthcare Automation Solutions
      </div>
      {products.map((product, index) => (
        <Tile
          key={index}
          title={product.title}
          subtitle={product.subtitle}
          descriptions={product.description}
          benefits={product.benefits}
          image={product.image}
          index={index}
          id={product.id}
          pgName={"ProductPage"}
        />
      ))}
    </div>
  );
};

export default ProductPage;
